import { daEventCenter } from 'public/src/services/eventCenter/index'

export const RIGHTS_FUNS_CONFIG = {
  newUserCrowdIds: [
    '44670', 
    '10728', 
    '1463'
  ],
  newUserDataCenterMap: {
    'central': 44670,
    'usa': 1463,
    'eur': 10728,
  }
}

export function isMatchedNewUser(idStr, currentDataCenter) {
  const ids = idStr.split(',')
  return ids.find(i => i == RIGHTS_FUNS_CONFIG.newUserDataCenterMap[currentDataCenter])
}

export function freeShippingDaEvent({
  freeShippingInfo = {},
  isPlatForm = false,
  singleMallCode = '',
  mallShippingAnalysisParam = {},
}) {
  const { dataFrom = null, info = {} } = freeShippingInfo
  if (!dataFrom) return
  let extraData
  if (dataFrom === 'newFreeShippingInfo') {
    extraData = {
      is_fullshippingactivity: '1',
      show_position: 4,
      type: info.type,
      origin_price: info.type === 'coupon'
        ? info._originInfo?.other_coupon_rule?.[0]?.minOrderPrice?.amountWithSymbol || ''
        : info._originInfo?.shippingInfoAvailable?.origin_price?.amountWithSymbol || '-', 
      shipping_method: info.type === 'coupon'
        ? info._originInfo?.shipping_list?.map(item => item.shipping_method_name)?.join(',') || ''
        : info._originInfo?.shippingInfoAvailable?.shipping_method_name || '',
      shipping_free_type: info.type === 'coupon'
        ? info._originInfo?.coupon_dimension == 1 ? 0 : 3
        : isPlatForm ? 0 : 3,
      mall_code: info.type === 'coupon'
        ? info._originInfo?.mall?.mall_code || ''
        : isPlatForm ? '' : singleMallCode,
      show_way: '-'
    }
  }
  if (dataFrom === 'topShippingInfo') {
    const type = isPlatForm ? 'platform' : singleMallCode
    const shippingAnalysisParam = mallShippingAnalysisParam[type] || {}
    extraData = {
      ...shippingAnalysisParam,
      mall_code: isPlatForm ? '' : singleMallCode,
      show_position: 4,
      show_way: '-',
    }
  }
  daEventCenter.triggerNotice({
    daId: '1-8-5-3',
    extraData
  })
}
