<template>
  <div
    class="j-cart-header cart-header"
  >
    <!--导航栏 -->
    <cart-nav class="j-cart-nav" />
    <!-- 实时标签 -->
    <div
      v-if="!locals.IS_RW && realtimeBehaviorLabels.length"
      class="cart-labels"
    >
      <RealtimeType
        :language="language"
        :labels="realtimeBehaviorLabels"
        :ele-info="{
          'headerEle': '.j-cart-header .j-cart-nav',
        }"
        :cart-leave="cartLeave"
      />
    </div>
    <!-- 公告提示 -->
    <CartTopNotice
      v-if="showNotice && cartNoticeMessage"
      v-show="!batchActive"
      :class="{ 'is-ssr': isSSR }"
    />
    <unPaidOrderTip
      v-if="CartUnpaidOrder"
      v-show="showUnPaidOrder"
      :order="unPaidOrderInfo.orderData"
      :language="language"
      @update-order="handleUpdateOrder"
      @jump-to-order="handleViewOrder"
      @close="handleCloseOrder"
    />
    <client-only>
      <CanceledOrderTip
        v-if="showCanceledOrderTip"
        v-show="!batchActive"
        @open-cancenled-goods-list="handleOpenGoodsList"
        @close="handleCloseCanceledOrder"
      />
    </client-only>
    <!-- 购物车权益条 -->
    <CartRightsFuns v-if="showRightsFuns" />
    <!-- 免邮 -->
    <TopFreeShipping
      v-if="showTopShipping"
      v-show="!batchActive"
    />
    <!-- quickShip -->
    <client-only>
      <CartQuickShipTop
        v-if="showQuickShipOnTop"
        v-show="!batchActive"
      />
    </client-only>
    <!-- 未登陆提示 -->
    <div
      v-if="!batchActive && carts && carts.length && showLogin"
      da-event-expose="1-8-7-2"
      da-event-click="1-8-1-8"
      @click="clickLogin('cart')"
    >
      <s-alert type="info">
        {{ language.SHEIN_KEY_PWA_14964 }}
        <s-button
          slot="operation"
          :type="['H48PX', 'primary']"
        >
          {{ language.SHEIN_KEY_PWA_15578 }}
        </s-button>
      </s-alert>
    </div>
    <ClientOnly>
      <s-network-error-alert :description="language.SHEIN_KEY_PWA_31743" />
    </ClientOnly>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import cartNav from './cartNav.vue'
import CartTopNotice from '../CartTopNotice.vue'
import RealtimeType from 'public/src/pages/cartNew/components/behaviorLabel/RealtimeType.vue'
import unPaidOrderTip from '../unPaidOrderTip/unPaidOrderTip.vue'
import CartQuickShipTop from '../CartQuickShipTop.vue'
import TopFreeShipping from './TopFreeShip.vue'
// import { queryLatestUnPaidOrderInfo } from '../../utils/fetcher.js'
import { stringifyQueryString } from '@shein/common-function'
import { NetworkErrorAlert as SNetworkErrorAlert } from '@shein/sui-mobile'
import { updateSessionOrderCache } from '../canceledOrderTip/canceledOrderTools.js'
import CartRightsFuns from '../cartRightsFuns/index.vue'

export default {
  // 组件注册
  components: {
    cartNav,
    CartTopNotice,
    RealtimeType,
    unPaidOrderTip,
    CartQuickShipTop,
    TopFreeShipping,
    SNetworkErrorAlert,
    CanceledOrderTip: () => import(/* webpackChunkName: "canceled_order_tip" */'../canceledOrderTip/CanceledOrderTip.vue'),
    CartRightsFuns,
  },
  // 组件传参
  props: {
    isSSR: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isShowUnPaidOrder: true,
    }
  },
  computed: {
    ...mapState('cart', ['language', 'loginStatus', 'locals', 'batchActive', 'cartNoticeMessage', 'realtimeBehaviorLabels', 'unPaidOrderInfo', 'cartAbtInfo', 'cartQsTagInfo', 'soldoutIds', 'canceledOrderInfo', 'canceledOrderTipTimer', 'showCanceledOrderSwitch', 'isNewUserOfTsp', 'isFreeReturn', 'cartLeave']),
    ...mapGetters('cart', ['carts', 'cartShippingTop', 'showQuickShipTag', 'cartcancelledTip', 'showFreeShipping4RightsFuns', 'showCartRights', 'cartRightsForOldUser', 'isShowNewPromoBar']),
    showLogin () {
      return !this.loginStatus && (this.locals.WEB_CLIENT == 'shein') && !this.batchActive
    },
    showNotice () {
      return !(this.carts.length && !this.loginStatus) // 判断未登录+非空购物车场景下，不需要展示公告
    },
    showUnPaidOrder() {
      return this.isShowUnPaidOrder && this.carts.length > 0 && this.loginStatus && this.unPaidOrderInfo?.orderData?.billno && !this.batchActive
    },
    CartUnpaidOrder() {
      return this.cartAbtInfo?.CartUnpaidOrder?.param?.cart_unpaidOrder == 'on'
    },
    showCanceledOrderTip() {
      return !(this.showUnPaidOrder && this.CartUnpaidOrder) && this.cartcancelledTip && this.canceledOrderInfo?.billno && this.showCanceledOrderSwitch && this.carts.length > 0
    },
    // 是否展示权益条
    // 新客：满足免邮或满足免退（存在免邮和免退仅展示一个的场景）
    // 老客：开启老客权益展示abt && 满足免邮，（在满足免邮后才展示权益条，不存在免退单独展示的场景）
    showRightsFuns() {
      return this.loginStatus && this.showCartRights && !this.batchActive && (
        (this.isNewUserOfTsp && (this.showFreeShipping4RightsFuns || this.isFreeReturn)) ||
        (!this.isNewUserOfTsp && ['planA', 'planB'].includes(this.cartRightsForOldUser) && this.showFreeShipping4RightsFuns)
      )
    },
    // 是否展示免邮模块
    // 开启权益条展示：新客-未满足免邮时展示免邮模块提示凑单；老客-开启老客权益条且未满足免邮时展示免邮模块提示凑单
    // 未开启权益条展示：走原有免邮模块置顶逻辑
    showTopShipping() {
      if(this.isShowNewPromoBar) return false
      if (this.showCartRights && (this.isNewUserOfTsp || ['planA', 'planB'].includes(this.cartRightsForOldUser))) {
        return !this.showFreeShipping4RightsFuns
      }
      return this.cartShippingTop
    },
    // 购物车顶部qs信息栏展示条件: 
    // 开启quickship标签展示abt && qs标签为新样式 && qs时效信息展示位置命中up && qs时效信息不为空 && 购物车有效商品行至少有一个是qs商品
    showQuickShipOnTop() {
      return this.showQuickShipTag
        && this.cartQsTagInfo?.isShowNewStyle
        && this.cartQsTagInfo?.qsTimelinessInfo?.isLocationTop
        && this.cartQsTagInfo?.qsTimelinessInfo?.tip
        && this.carts.length > 0
        && this.carts
          .filter(item => !this.soldoutIds.includes(item.id))
          .findIndex(item => item.quick_ship == 1) > -1
    }
  },
  watch: {
    showUnPaidOrder: {
      handler(val){
        if(typeof window == 'undefined') return
        if(val) {
          updateSessionOrderCache(this.unPaidOrderInfo?.orderData?.billno)
        }
      },
      immediate: true
    },
    showCanceledOrderTip: {
      handler(newVal, oldVal) {
        if(typeof window == 'undefined') return
        if(newVal && newVal != oldVal) {
          if(this.canceledOrderTipTimer) {
            clearTimeout(this.canceledOrderTipTimer)
          }
          this.updateState({
            key: 'canceledOrderTipTimer',
            value: setTimeout(() => {
              this.updateState({
                key: 'showCanceledOrderSwitch',
                value: false
              })
            }, 60000)
          })
          updateSessionOrderCache(this.canceledOrderInfo?.billno)
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('cart', ['updateState']),
    clickLogin (type) {
      this.$emit('login-handle', type)
    },
    async handleUpdateOrder() { // 未支付订单 客户端渲染兜底 pageId=1350582498
      return 
      /*       
      if(this.cartAbtInfo?.EmptyCartUnpaidOrder?.param?.empty_cart_unpaidOrder == 'off') return
      let data = await queryLatestUnPaidOrderInfo()
      if(data?.orderData?.billno) {
        this.updateState({ 
          key: 'unPaidOrderInfo',
          value: data
        })
      }
      */
    },
    handleViewOrder(billno, queryParams) {
      let url = `${this.locals.langPath}/user/orders/detail/${billno}?${stringifyQueryString({ queryObj: queryParams })}`
      this.$routerPush(url)
    },
    handleOpenGoodsList() {
      this.updateState({
        key: 'showCanceledOrderDraw',
        value: true
      })
      if(this.canceledOrderTipTimer) {
        clearTimeout(this.canceledOrderTipTimer)
        this.updateState({
          key: 'canceledOrderTipTimer',
          value: null
        })
      }
    },
    handleCloseOrder(){
      this.isShowUnPaidOrder = false
    },
    handleCloseCanceledOrder() {
      if(this.canceledOrderTipTimer) {
        clearTimeout(this.canceledOrderTipTimer)
        this.updateState({
          key: 'canceledOrderTipTimer',
          value: null
        })
      }
      this.updateState({
        key: 'showCanceledOrderSwitch',
        value: false
      })
    }
  }
}
</script>

<style lang='less' scoped>
.cart-header {
  position: sticky;
  top: 0px;
  z-index: @zindex-header;
}
.cart-labels {
  margin-top: -1/37.5rem;
}
</style>
