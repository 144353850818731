<template>
  <div
    ref="container"
    v-expose="{
      id: '1-8-3-73',
      data: {
        ...getExposeAnalysisData()
      }
    }"
    class="cart-rightsFuns-tip_new"
  >
    <div class="left-tip">
      <template v-if="showFreeShipping">
        <FreeShippingIcon />
        <span
          ref="leftItem1"
          :class="['free-shipping-text', { 'margin-r': isFreeReturn }]"
        >{{ language.SHEIN_KEY_PWA_32027 }}</span>
      </template>
      <template v-if="isFreeReturn">
        <FreeReturnIcon />
        <span
          ref="leftItem2"
          class="free-return-text"
        >{{ language.SHEIN_KEY_PWA_32028 }}</span>
      </template>
    </div>
    <div
      ref="rightItem"
      class="tip-right"
    >
      {{ rightItemText }}
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, nextTick, watch } from 'vue'
import FreeShippingIcon from './FreeShippingIcon.vue'
import FreeReturnIcon from './FreeReturnIcon.vue'

const props = defineProps({
  language: {
    type: Object,
    default: () => ({}),
  },
  isFreeReturn: {
    type: Boolean,
    default: false,
  },
  showFreeShipping: {
    type: Boolean,
    default: false,
  },
  freeShippingInfo: {
    type: Object,
    default: () => ({}),
  }
})

watch(() => props.showFreeShipping, () => {
  adjustFontSizeAndLayout()
})

const container = ref(null)
const leftItem1 = ref(null)
const leftItem2 = ref(null)
const rightItem = ref(null)
const rightItemText = ref(props.language?.SHEIN_KEY_PWA_32029)

async function adjustFontSizeAndLayout() {
  await nextTick()
  container.value.classList.remove('flow-layout')
  if (leftItem1.value) leftItem1.value.style.fontSize = '12px'
  if (leftItem2.value) leftItem2.value.style.fontSize = '12px'
  if (rightItem.value) rightItem.value.style.fontSize = '14px'
  if (rightItem.value) {
    if (rightItem.value.scrollWidth > rightItem.value.clientWidth || rightItem.value.clientWidth == 0) {
      if (leftItem1.value) leftItem1.value.style.fontSize = '10px'
      if (leftItem2.value) leftItem2.value.style.fontSize = '10px'
      rightItem.value.style.fontSize = '12px'
      container.value.classList.add('flow-layout')
    }
  } 
}

function getExposeAnalysisData() {
  let type = []
  if(props.isFreeReturn) type.push('freereturn')
  if(props.showFreeShipping) type.push('freeship')
  return {
    right_type: type.join(',')
  }
}

onMounted(() => {
  adjustFontSizeAndLayout()
  window.addEventListener('resize', adjustFontSizeAndLayout)
})

</script>
<style lang="less" scoped>
.cart-rightsFuns-tip_new {
  width: 100%;
  min-height: 64/75rem;
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 8/75rem 24/75rem;
  background: #FFF3D3;
  gap: 0 20/75rem;
  .left-tip {
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    padding:  8/75rem 0;
    max-width: 100%;
    color: #0E8C33;
    font-size: 12px;
    font-weight: 590;
    flex-wrap: nowrap;
    svg,
    .free-shipping-text {
      flex-shrink: 0;
    }
    svg {
      margin-right: 8/75rem;
    }
    .free-shipping-text,
    .free-return-text {
      line-height: 32/75rem;

    }
    .margin-r {
      margin-right: 16/75rem;
    }
    .free-return-text {
      flex-shrink: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .tip-right {
    color: #FF3351;
    font-size: 14px;
    font-weight: 700;
    flex-shrink: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &.flow-layout {
    flex-wrap: wrap;
    .left-tip {
      padding: 0;
    }
    .tip-right {
      line-height: 32/75rem;
    }
  }
}
</style>
