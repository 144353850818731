<template>
  <div
    v-if="stockTip.tip"
    :class="['cart-item__insufficientStock', stockTip.className]"
  >
    <ResizeFont
      :is-multiline="true"
      :multiline-num="2"
    >
      <ResizeFontText
        :init-font-size="12"
        :resize-font-min="8"
        :resize-font-step="1"
      >
        {{ stockTip.tip }}
      </ResizeFontText>
    </ResizeFont>
  </div>
</template>
<script setup>
import { defineProps, computed } from 'vue'
import { template } from '@shein/common-function'
import ResizeFont from 'public/src/pages/cartNew/components/ResizeFont.vue'
import ResizeFontText from 'public/src/pages/cartNew/components/ResizeFontText.vue'

const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  },
  config: {
    type: Object,
    default: () => ({})
  },
  ctx: {
    type: Object,
    default: () => ({})
  }
})

const stockTip = computed(() => {
  return getStockBar(props.item, props.config, props.ctx)
})

function getStockBar(item, config = {}, ctx = {}) {
  const {
    language = {},
    soldoutIds = [],
    flashSaleType = 0,
    cartDiscountLogoA = false,
  } = ctx || {}
  let fewClassName = cartDiscountLogoA ? '' : 'cart-item__few'
  if (item.isGiftItem) return {
    // 赠品腰带不属于库存信息，不受abt控制
    tip: language.SHEIN_KEY_PWA_23895,
    className: item.isSheinClubGiftItem ? 'cart-item__club-gift' : 'cart-item__few'
  }
  else if (config?.showAddon && item.isAddItem) return {
    tip: language.SHEIN_KEY_PWA_29434,
    className: 'cart-item__add-on'
  }
  else if ((item.product.is_on_sale == '0' || soldoutIds.includes(item.id))) {
    if (item.isInvalid) {
      return {
        tip: language.SHEIN_KEY_PWA_31161,
        className: ''
      }
    } else {
      return {
        tip: language.SHEIN_KEY_PWA_14987,
        className: ''
      }
    }
  } else if (item.isShowOnlyXLeftBelt) {
    let flashClassName = [-1, 1, 4, 5].includes(flashSaleType) && !cartDiscountLogoA ? 'cart-item__flash' : ''
    return {
      tip: template(item.product.real_stock, language.SHEIN_KEY_PWA_23770),
      className: `${fewClassName} ${flashClassName}`
    }
  } else if (item.isShowSoldOutBelt) return {
    tip: language.SHEIN_KEY_PWA_32907,
    className: ''
  }
  else return {}
}

</script>
<style lang="less" scoped>
.cart-item__insufficientStock {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, .6);
  padding: 4/75rem 8/75rem;
  line-height: 1;
  color: #fff;
  .font-dpr(24px);
  width: 100%;
  text-align: center;

  // span {
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   display: -webkit-box;
  //   -webkit-line-clamp: 1;
  //   -webkit-box-orient: vertical;
  // }
  &.cart-item__few {
    background: rgba(250, 99, 56, 0.8);

    & when (@IS_RW) {
      background: rgba(255, 105, 110, 0.8);
    }

    &.cart-item__flash {
      background: rgba(252, 211, 100, 0.9);

      & when (@IS_SH) {
        color: #222;
      }

      & when (@IS_RW) {
        background: rgba(252, 64, 112, 0.8)
      }
    }
  }
  &.cart-item__add-on {
    background: @sui_color_micro_emphasis_bg;
    color: @sui_color_micro_emphasis;
  }
  &.cart-item__club-gift {
    background: rgba(135, 60, 0, 0.80);
  }
}
</style>
