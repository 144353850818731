var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"discountTag",class:[
    'discount-tag',
    _vm.primeClassName,
    {
      'has-estimated': _vm.showEstimatedTag,
      'hidden': _vm.hiddenDiscountTag
    }
  ],on:{"click":_vm.onClick}},[_c('span',{staticClass:"discount"},[_vm._v("\n    "+_vm._s(_vm.discountText)+"\n  ")]),_vm._v(" "),(!_vm.isSoldout && !_vm.showEstimatedTag)?[(_vm.showDiscountCountdown)?[_c('i',{staticClass:"line"},[_vm._v("|")]),_vm._v(" "),_c('count-down-time',{attrs:{"font-size":"10px","not-hide":"","not-reload":"","time-stamp":_vm.timeStamp}})]:_vm._e(),_vm._v(" "),(_vm.discountDetailSwitch)?[_c('Icon',{staticClass:"discount-tag__more-right",attrs:{"name":"sui_icon_more_right_16px","size":"14px","color":_vm.isPremiumMember ? _vm.primeColor : '#FA6338'}})]:_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }