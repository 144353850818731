var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'cart-rightsFuns-tip_oldA',
    {
      'only-free-ship': !_vm.isFreeReturn,
      'shipping-and-return': _vm.isFreeReturn && !_setup.hasCountDown,
      'shipping-and-return_countdown': _vm.isFreeReturn && _setup.hasCountDown,
    }
  ]},[_c('div',{staticClass:"left-tip"},[(!_vm.isFreeReturn)?[_c(_setup.Icon,{staticClass:"item-icon",attrs:{"is-responsive-name":"","name":"sui_icon_selected_16px","size":"16px","color":"rgba(25, 128, 85, 1)"}}),_vm._v(" "),_c('div',{staticClass:"tip-text",domProps:{"innerHTML":_vm._s(_setup.freeShippingTip)}})]:[_c('div',{staticClass:"free-shipping"},[_c(_setup.FreeShippingIcon),_vm._v(" "),_c('span',{ref:"freeShippingText",staticClass:"free-shipping-text"},[_vm._v(_vm._s(_vm.language.SHEIN_KEY_PWA_32027))])],1),_vm._v(" "),_c('div',{staticClass:"free-return"},[_c(_setup.FreeReturnIcon),_vm._v(" "),_c('span',{ref:"freeReturnText",staticClass:"free-return-text"},[_vm._v(_vm._s(_vm.language.SHEIN_KEY_PWA_32028))])],1)]],2),_vm._v(" "),(_setup.hasCountDown)?[_c('ClientOnly',[_c(_setup.CountDown,{staticClass:"count-down",attrs:{"font-size":"10px","not-hide":"","not-reload":"","time-stamp":_setup.countDownTime,"is-left-time":_setup.isLeftTime}})],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }