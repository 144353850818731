<template>
  <!-- 老客权益a样式 -->
  <div
    :class="[
      'cart-rightsFuns-tip_oldA',
      {
        'only-free-ship': !isFreeReturn,
        'shipping-and-return': isFreeReturn && !hasCountDown,
        'shipping-and-return_countdown': isFreeReturn && hasCountDown,
      }
    ]"
  >
    <div class="left-tip">
      <!-- 仅有freeshipping权益 -->
      <template v-if="!isFreeReturn">
        <Icon
          is-responsive-name
          class="item-icon"
          name="sui_icon_selected_16px"
          size="16px"
          color="rgba(25, 128, 85, 1)"
        />
        <div 
          class="tip-text" 
          v-html="freeShippingTip"
        >
        </div>
      </template>
      <!-- freeshipping与freereturn共存时 -->
      <template v-else>
        <!-- freeshipping权益 -->
        <div class="free-shipping">
          <FreeShippingIcon />
          <span
            ref="freeShippingText"
            class="free-shipping-text"
          >{{ language.SHEIN_KEY_PWA_32027 }}</span>
        </div>
        <!-- freereturn权益 -->
        <div class="free-return">
          <FreeReturnIcon />
          <span
            ref="freeReturnText"
            class="free-return-text"
          >{{ language.SHEIN_KEY_PWA_32028 }}</span>
        </div>
      </template>
    </div>
    <template v-if="hasCountDown">
      <ClientOnly>
        <count-down
          class="count-down"
          font-size="10px"
          not-hide
          not-reload
          :time-stamp="countDownTime"
          :is-left-time="isLeftTime"
        />
      </ClientOnly>
    </template>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, nextTick } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import FreeShippingIcon from './FreeShippingIcon.vue'
import FreeReturnIcon from './FreeReturnIcon.vue'
import CountDown from 'public/src/pages/cartNew/components/cartActivity/countDown.vue'

const props = defineProps({
  language: {
    type: Object,
    default: () => ({}),
  },
  isFreeReturn: {
    type: Boolean,
    default: false,
  },
  showFreeShipping: {
    type: Boolean,
    default: false,
  },
  freeShippingInfo: {
    type: Object,
    default: () => ({}),
  }
})

const freeShippingTip = computed(() => {
  return props.freeShippingInfo.info?.freeShippinpTips || props.freeShippingInfo.info?.shippingTip || ''
})
const hasCountDown = computed(() => {
  return props.freeShippingInfo.info?.showCountDown || props.freeShippingInfo.info?.shippingInfoAvailable?.show_count_down || false
})
const countDownTime = computed(() => {
  return props.freeShippingInfo.info?.countDownTime || props.freeShippingInfo.info?.shippingInfoAvailable?.count_down_time || null
})
const isLeftTime = computed(() => {
  return props.freeShippingInfo.info?.type === 'coupon' ? false : true
})

const freeShippingText = ref(null)
const freeReturnText = ref(null)

// 计算内容长度，超出字体缩小为10px
const adjustFontSize = () => {
  if (freeShippingText.value?.scrollWidth > freeShippingText.value?.clientWidth || freeReturnText.value?.scrollWidth > freeReturnText.value?.clientWidth) {
    freeShippingText.value.style.fontSize = '10px'
    freeReturnText.value.style.fontSize = '10px'
  }
  if (freeShippingText.value?.scrollWidth > freeShippingText.value?.clientWidth || freeReturnText.value?.scrollWidth > freeReturnText.value?.clientWidth) {
    if (hasCountDown.value) {
      document.querySelector('.count-down').style.display = 'none'
    }
  }
}

onMounted(async() => {
  await nextTick()
  adjustFontSize()
})
</script>

<style lang="less" scoped>
.cart-rightsFuns-tip_oldA {
  width: 100%;
  display: inline-flex;
  align-items: center;
  height: 64/75rem;
  padding: 16/75rem 24/75rem;
  background: #FFF3D3;
  overflow: hidden;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 16/75rem;
  flex-wrap: nowrap;
  .left-tip {
    max-width: 100%;
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    font-weight: 590;
  }
  /deep/.cont-down {
    flex-shrink: 0;
    margin-left: 16/75rem;
    span {
      display: inline-block;
      text-align: center;
      background: rgba(0, 0, 0, 1);
      color: rgba(255, 255, 255, 1);
      border-radius: 1px;
      width: 14px;
      height: 14px;
      line-height: 14px;
      font-size: 10px;
    }
  }
  .free-shipping,
  .free-return {
    display: inline-flex;
    align-items: center;
    color: #0E8C33;
    flex-wrap: nowrap;
    svg {
      flex-shrink: 0;
      margin-right: 8/75rem;
    }
  }
  &.only-free-ship {
    .left-tip {
      flex-shrink: 1;
      overflow: hidden;
      .item-icon {
        margin-right: 4/75rem;
      }
      .tip-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .new-down-time {
      flex-shrink: 0;
    }
  }
  &.shipping-and-return {
    .left-tip {
      width: 100%;
      .free-shipping,
      .free-return {
        justify-content: center;
        width: 50%;
        .free-shipping-text,
        .free-return-text {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .free-shipping {
        padding-right: 24/75rem;
      }
      .free-return {
        border-left: solid 1px rgba(241, 221, 165, 1);
        padding-left: 24/75rem;
      }
    }
    svg {
      margin-right: 8/75rem;
    }
  }
  &.shipping-and-return_countdown {
    .left-tip {
      gap: 16/75rem;
      overflow: hidden;
      flex-shrink: 1;
      .free-shipping {
        flex-shrink: 0;
        max-width: 100%;
      }
      .free-return {
        flex-shrink: 1;
        overflow: hidden;
      }
      .free-shipping-text,
      .free-return-text {
        flex-shrink: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .count-down {
      overflow: hidden;
      flex-shrink: 0;
    }
  }
}
</style>
