<template>
  <div>
    <div
      v-if="carts.length"
      v-show="!batchActive"
      class="cart-checkout j-cart-bottom"
    >
      <ClientOnly>
        <template v-if="isShowNewPromoBar">
          <CartActivityNew
            v-if="promotionCouponMix"
            v-show="!showDiscountDetails && !showIncentiveProductListDialog"
          />
        </template>
        <template v-else>
          <template v-if="showCouponHelperEnter">
            <div
              v-show="!batchActive && carts.length > 0 && !showDiscountDetails && !showIncentiveProductListDialog"
              v-enterkey
              class="c-coupon-helper-bubble j-coupon-helper-bubble"
              tabindex="0"
              role="button"
              @click="showCouponHelper"
            >
              <coupon-helper />
            </div>
          </template>
          <CartActivity
            v-if="!showDiscountDetails && !showIncentiveProductListDialog" 
          />
        </template>
        <transition name="recommend-guide-fade">
          <RecommendGuideA
            v-if="showRecommendGuideA"
            class="recommend-guide-a"
          />
        </transition>
        <CartRuleInfo v-if="showCartRuleInfo" />
      </ClientOnly>

      <!-- 折扣信息 -->
      <Transition 
        name="discount-details"
        @after-enter="onAfterEnter"
      >
        <discountDetailsV2
          v-if="showDiscountDetails"
          ref="discountDetailsV2"
          :show="showDiscountDetails && (isShowDiscountDetailsEntry || checkedItemIds.length > 0)"
          :checkedItemIds="checkedItemIds"
          @close="handleDiscountDetails(false)"
          @updateCheckedItemIds="updateCheckedItemIds"
        />
      </Transition>
      <ClientOnly>
        <Transition name="discount-details">
          <IncentiveProductList
            v-if="showIncentiveProductListDialog"
            :is-show="showIncentiveProductListDialog"
            @close="handleIncentiveProductListDialog(false)"
          />
        </Transition>
      </ClientOnly>

      <Transition name="vue-slide-layer-bottom-fade">
        <div
          v-show="!batchActive"
          id="bottomPop"
          class="c-cart-checkout j-cart-checkout macc-pad-wrapper j-header-and-footer new-checkout"
          :class="[
            isIPhoneX && 'isIPhoneX',
          ]"
        >
          <!-- 限购 -->
          <p
            v-if="memberLimit.goods && memberLimit.goods.length"
            class="cart-checkout__memberLimit"
            v-html="template(memberLimit.times, language.SHEIN_KEY_PWA_14968)"
          >
          </p>
          
          <!-- BNPL 结算 B 方案 popover 气泡 -->
          <Transition name="bnpl-popover">
            <div
              v-if="payShowStyleB && !hasCloseBNPLPopover && !showDiscountDetails && !showIncentiveProductListDialog"
              class="cart-checkout-bnpl__popover-wrap"
            >
              <CartBNPLPopover
                placement="bottom" 
                @close="hasCloseBNPLPopover = true"
              />
            </div>
          </Transition>

          <!-- 普通版本结算 BAR -->
          <Transition name="normal-bar">
            <CartCheckoutBarNormal
              v-show="!showPayBtn"
              :update-checked-item-ids="updateCheckedItemIds"
              :handle-discount-details="handleDiscountDetails"
              :total-price-amount-with-symbol="totalPriceAmountWithSymbol"
              :is-show-discount-details-entry="isShowDiscountDetailsEntry"
              :show-filter-label-popover="!showPayBtn ? checkoutButtonPopoverType : ''"
              :prefetch-checkout="prefetchCheckout"
              :font-size="fontSize"
              :checkout-name-text="checkoutNameText"
              :checked-quantity="checkedQuantity"
              :control-checkout-name-text="controlCheckoutNameText"
              :show-new-free-shipping="showNewFreeShipping"
              :click-checkout="clickCheckout"
              :icon-prefix="iconPrefix"
              :language="language"
              :show-quick-ship="showQuickShip"
            />
          </Transition>
          <!-- BNPL 版本结算 BAR -->
          <Transition name="bnpl-bar">
            <CartCheckoutBarBNPL
              v-show="showPayBtn"
              :update-checked-item-ids="updateCheckedItemIds"
              :handle-discount-details="handleDiscountDetails"
              :total-price-amount-with-symbol="totalPriceAmountWithSymbol"
              :is-show-discount-details-entry="isShowDiscountDetailsEntry"
              :show-filter-label-popover="showPayBtn ? checkoutButtonPopoverType : ''"
              :prefetch-checkout="prefetchCheckout"
              :font-size="fontSize"
              :checkout-name-text="checkoutNameText"
              :checked-quantity="cartCheckedNum"
              :control-checkout-name-text="controlCheckoutNameText"
              :show-new-free-shipping="showNewFreeShipping"
              :click-checkout="clickCheckout"
              :icon-prefix="iconPrefix"
              :language="language"
              :show-quick-ship="showQuickShip"
              :show="showPayBtn"
              :pay-show-style-a="payShowStyleA"
            />
          </Transition>
        </div>
      </Transition>
    </div>

    <s-dialog
      v-if="showFlashSaleTip && IS_RW"
      :visible.sync="showFlashSaleTip"
      :append-to-body="true"
      :close-on-click-modal="false"
    >
      <template slot="title">
        {{ language.SHEIN_KEY_PWA_22005 }}
      </template>
      <div class="flashSale-over-tip">
        <div
          v-for="item in flashSaleOverTip"
          :key="item.flashSaleType"
          class="tip-item"
          v-html="item.tip"
        ></div><br />
      </div>
      <template slot="footer">
        <s-button
          :type="['primary']"
          width="100%"
          @click="showFlashSaleTip=false"
        >
          {{ language.SHEIN_KEY_PWA_15312 }}
        </s-button>
      </template>
    </s-dialog>
    <CartFlashSaleDraw
      v-if="showFlashSaleTip && !IS_RW"
      :show-flash-sale-tip="showFlashSaleTip"
      :flash-sale-data="flashSaleOverTip"
      @closeFlashSaleTip="showFlashSaleTip=false"
      @handleFilter="handleFilter"
    />
    <LowPriceUserIndex
      v-if="loadLowPriceUser"
      :show-new-free-shipping="showNewFreeShipping"
      :show-quick-ship="showQuickShip"
      @emitClickCheckoutEvt="emitClickCheckoutEvt"
      @goCheckoutEvt="goCheckoutEvt"
    />
    <ClientOnly>
      <!-- paypal回调邮箱后确认信息弹框 -->
      <!-- <email-confirm-dialog v-if="asyncComponentLoadedStatus.cartEmailConfirmDrawIsLoaded" /> -->
      <email-confirm-dialog v-if="asyncComponentLoadedStatus.cartEmailConfirmDrawIsLoaded" @goCheckoutPageByPaypal="goCheckoutPageByPaypal" />
      <!-- 是有paypal回调邮箱进行注册账号弹出 -->
      <account-register-dialog v-if="asyncComponentLoadedStatus.cartAccountRegisterDrawIsLoaded" />
      <!-- 快速注册失败弹框 -->
      <register-failed-dialog v-if="asyncComponentLoadedStatus.cartRegisterFailedDrawIsLoaded" @goCheckoutPageByPaypal="goCheckoutPageByPaypal" />
    </ClientOnly>
  </div>
</template>
<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex'
import { abtservice } from 'public/src/services/abt'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import ClientOnly from 'vue-client-only'
import { cartEmphasizePromotionAll, handleOrderOrSingleIsMeetOrOver, amountWithSymbolTransform, getBrowserName } from '../utils'
import { checkStock } from '../utils/fetcher'
import { isIPhoneX, template } from '@shein/common-function'
import UserInfoManager from 'public/src/services/UserInfoManager/index.js'
import apiReport from 'public/src/pages/common/apiReport'
import { CheckoutTipCache, showFilterItem } from '../utils/cartFilterLabels.js'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
// import { loadComps } from 'public/src/pages/checkout/components/fs_async_comps.js'
import prerenderCheckout from '../utils/prerender'
import CartCheckoutBarBNPL from './CartCheckoutBarBNPL.vue'
import CartCheckoutBarNormal from './CartCheckoutBarNormal.vue'
import CartBNPLPopover from './CartBNPLPopover.vue'
import { web_checkout_button_click, web_checkout_button_jump } from '../utils/metricTagsConfig.js'
import PaypalSDK from 'public/src/pages/common/paypal/paypalSdk.js'
import RecommendGuideA from '@/public/src/pages/cartNew/components/recommendGuide/RecommendGuideA.vue'

daEventCenter.addSubscriber({ modulecode: '1-8-2' })

const getAutoCouponCodes = (infos) => {
  if (!Array.isArray(infos) || !infos.length) return ''
  return '&auto_coupon=' + infos.map((v) => (v.coupon_code || '').toUpperCase()).join(',')
}

const getPaymentCode = (paymentCode) => {
  if (paymentCode) {
    return `&paymentCode=${paymentCode}`
  }
  return ''
}

const getFoldPayment = (needFoldPayment, firstParam) => {
  if (needFoldPayment) {
    if (firstParam) {
      return `?foldPayment=1` // 折叠支付方式列表
    } else {
      return `&foldPayment=1` // 折叠支付方式列表
    }
  }
  return ''
}

export default {
  components: {
    ClientOnly,
    CartRuleInfo: () => import(/* webpackChunkName: "cart-rule-info" */ './CartRuleInfo/index.vue'),
    discountDetailsV2: () => import(/* webpackChunkName: "cart-checkout-discount-details" */ './checkoutDiscountDetails/index.vue'),
    CouponHelper: () => import('./coupon/CouponHelper/EntryBubble.vue'),
    LowPriceUserIndex: () => import('./lowPriceUser/index.vue'),
    CartActivity: () => import('./cartActivity/index.vue'),
    CartActivityNew: () => import('./cartActivityNew/index.vue'),
    CartFlashSaleDraw: () => import('./cartFlashSale/CartFlashSaleDraw.vue'),
    IncentiveProductList: () => import('./cartCheckoutIncentive/IncentiveProductList.vue'),
    emailConfirmDialog: () => import(/* webpackChunkName: "cart-email-confirm-dialog" */'public/src/pages/cartNew/components/paypalLoginDialog/emailConfirmDialog.vue'),
    accountRegisterDialog: () => import(/* webpackChunkName: "cart-account-register-dialog" */'public/src/pages/cartNew/components/paypalLoginDialog/accountRegisterDialog.vue'),
    registerFailedDialog: () => import(/* webpackChunkName: "cart-register-failed-dialog" */'public/src/pages/cartNew/components/paypalLoginDialog/registerFailedDialog.vue'),
    CartCheckoutBarBNPL,
    CartCheckoutBarNormal,
    CartBNPLPopover,
    RecommendGuideA,
  },
  props: {
    showCouponHelperEnter: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      isIPhoneX: false,
      checkoutHeight: 0,
      showFlashSaleTip: false,
      flashSaleOverTip: [],
      fontSize: '14px',
      loadLowPriceUser: false,
      abtTest: {},
      lowerPriceParams: {},
      checkoutButtonPopoverType: '',
      filterLabelPopoverCache: null,
      checkedItemIds: [],
      hasCloseBNPLPopover: false,
      normalBarTransitionName: '',
      bnplBarTransitionName: '',
      isExposedFreeShippingIcon: false, // 是否曝光过免邮图标
      paypalGaBtn: false, // 是否展示paypal按钮
      autoHighlightDisicountType: '', // 自动展开优惠明细的类型
      isFirstRenderPaypal: true,
      scene: 'cart',
    }
  },
  computed: {
    ...mapState({
      rootCartInfo: 'cartInfo',
    }),
    ...mapGetters('cart', ['carts', 'memberLimit', 'cartCheckoutPromotionMap', 'mallShippingAnalysisParam', 'flashSaleGroupList', 'showQuickShipTag', 'showPrimeVipOverQuota', 'underPriceGroupList', 'underPriceAbtValue', 'iconPrefix', 'oldIconPrefix', 'CouponAddonStatus', 'doFilter', 'checkoutFreeShippingState', 'addOnPromotionList', 'paymentFrontPaymentList', 'payShowStyle', 'payShowStyleA', 'payShowStyleB', 'showTotalFinallyPriceEntry', 'showTotalOriginalPriceEntry', 'showTotalDiscountEntry', 'showTotalOriginalPriceEntryArrow', 'incentiveTimeConfig', 'showPaypalBtn', 'isShowNewPromoBar']),
    ...mapState('cart', ['isPlatForm', 'language', 'cartInfo', 'loginStatus', 'batchActive', 'locals', 'soldoutIds', 'cartAbtInfo', 'integrityPromotionInfo', 'shippingInfo', 'showDiscountDetails', 'flashSaleOverLimit', 'showLowPriceUser', 'couponInfo', 'lastCheckStockTime', 'filterType', 'isCheckedByLocal', 'discount', 'taxInclusiveTip', 'cartItemMap', 'cartsId', 'targetCouponInfo', 'goodsFilterLabels', 'showIncentiveProductListDialog', 'showIncentivePopover', 'showIncentiveTimes', 'incentivePopoverSwitchTimer', 'inducementSortList', 'cartQsTagInfo', 'renderPaypalBtnStatus', 'channelSessionParams', 'asyncComponentLoadedStatus', 'showRecommendGuideA', 
      'promotionCouponMix',
      'incentivePopoverContent',
    ]),
    showCartRuleInfo() {
      return gbCommonInfo.lang === 'br' && this.cartAbtInfo?.brTaxhint?.param?.isSwitchOn == '1'
    },
    localLang () {
      return this.locals.lang
    },
    local_GB_cssRight () {
      return this.locals.GB_cssRight
    },
    IS_RW () {
      return this.locals.IS_RW
    },
    totalPrice () {
      return this.cartInfo?.totalPrice?.amount
    },
    originPrice () {
      return this.cartInfo?.originPrice?.amount
    },
    totalDiscountPrice () {
      return this.cartInfo && this.cartInfo.total_discount_price && this.cartInfo.total_discount_price || {}
    },
    isShowDiscountDetailsEntry() {
      return this.showTotalOriginalPriceEntryArrow || this.showTotalFinallyPriceEntry || this.showTotalDiscountEntry || this.showPrimeVipOverQuota
    },
    cartCheckedNum () {
      let checkedNum = 0
      this.carts.filter(item => item.is_checked == 1).forEach(cartItem => {
        checkedNum += cartItem.quantity
      })
      return checkedNum
    },
    checkedQuantity(){
      return this.cartCheckedNum ? `(${this.cartCheckedNum})` : ''
    },
    flashSaleOverLimitType(){
      let overTypeSet = new Set()
      Object.values(this.flashSaleOverLimit).forEach(item => {
        if(item.isChecked && item.overType){
          overTypeSet.add(item.overType)
        }
      })
      return overTypeSet.size ? overTypeSet.size == 2 ? 0 : overTypeSet.has('singleOver') ? 'single' : 'total' : 0
    },
    totalPriceAmountWithSymbol() {
      return amountWithSymbolTransform(this.cartInfo.totalPrice?.amountWithSymbol || '', this.locals.currencyDecPointSymbol)
    },
    showNewFreeShipping(){
      return !!this.checkoutFreeShippingState
    },
    openControlLowPriceUser () {
      // 临时后端兼容性措施, 低客拦截abt
      let {
        ControlLowpriceUser = '',
      } = this.cartAbtInfo?.LowpriceUser?.param || {}
      if(!ControlLowpriceUser || ControlLowpriceUser == 'off') return 0

      // 校验阈值与差值, 并规范返回值,0不进行拦截, 1触发强拦截, 2触发弱拦截
      const { action, threshold_price, residue_price } = this.cartInfo?.touch_rule_list?.find(i => i.hint_type == 5) || {}
      if(action == 1 || action == 2) {
        return Number(residue_price?.amount || 0) > 0 && Number(threshold_price?.amount || 0) > 0 ? action : 0
      } else return 0
      /** 
      // 是否出现低客拦截弹框
      let {
        ControlLowpriceUser = '',
        ControlForcedInterception = ''
      } = this.cartAbtInfo?.LowpriceUser?.param || {}
      let {
        strong_intercept_diff = {},
        weak_intercept_diff = {},
        strong_intercept_threshold = {},
        weak_intercept_threshold = {},
      } = this.cartInfo || {}
      let { amount: strongDiffAmount = 0 } = strong_intercept_diff || {}
      let { amount: weakDiffAmount = 0 } = weak_intercept_diff || {}
      let { amount: strongAmount = 0 } = strong_intercept_threshold || {}
      let { amount: weakAmount = 0 } = weak_intercept_threshold || {}
      // 未开启低门槛 不弹提示框
      if (ControlLowpriceUser != 'on') return false

      // 强拦截或者弱拦截
      if (ControlForcedInterception == 'on') {
        // 强拦截
        return Number(strongAmount) > 0 && Number(strongDiffAmount) > 0
      } else {
        // 弱拦截
        return Number(weakAmount) > 0 && Number(weakDiffAmount) > 0
      }
      */
    },
    controlCheckoutNameText () {
      // checkout文案展示调整
      let {
        ControlLowpriceUser = '',
        ControlCheckoutname = '',
        // ControlForcedInterception = ''
      } = this.cartAbtInfo?.LowpriceUser?.param || {}

      // 临时后端兼容性措施, 低客拦截abt
      if(!ControlLowpriceUser || ControlLowpriceUser == 'off') return ''

      let { action, threshold_price, residue_price } = this.cartInfo?.touch_rule_list?.find(i => i.hint_type == 5) || {}
      if(!action) return ''
      else if((action == 1 || action == 2) && Number(residue_price?.amount || 0) > 0 && Number(threshold_price?.amount || 0) > 0) { // 强弱拦截按钮文案逻辑一致, 故合并
        if(ControlCheckoutname == 'on') return threshold_price?.amountWithSymbol || ''
        else return ''
      } else return '' // 未定义的值, 默认为关闭低客单拦截
      /**
      let {
        strong_intercept_diff = {},
        weak_intercept_diff = {},
        strong_intercept_threshold = {},
        weak_intercept_threshold = {},
      } = this.cartInfo || {}
      let { amount: strongDiffAmount = 0 } = strong_intercept_diff || {}
      let { amount: weakDiffAmount = 0 } = weak_intercept_diff || {}
      let { amount: strongAmount = 0, amountWithSymbol: strongAmountWithSymbol } = strong_intercept_threshold || {}
      let { amount: weakAmount = 0, amountWithSymbol: weakAmountWithSymbol } = weak_intercept_threshold || {}

      // 没有开启拦截abt  或者没有开启拦截名字abt
      if (ControlLowpriceUser != 'on' || ControlCheckoutname != 'on') return ''

      // 强拦截或者弱拦截
      if (ControlForcedInterception == 'on') {
        // 强拦截: 门槛值跟门槛差值没有值 则展示[checkout now] 否则展示低客门槛文案
        let hadThresholdVal = Number(strongAmount) > 0 && Number(strongDiffAmount) > 0
        return hadThresholdVal ? strongAmountWithSymbol : ''
      } else {
        // 弱拦截: 门槛值跟门槛差值没有值 则展示[checkout now] 否则展示低客门槛文案
        let hadThresholdVal = Number(weakAmount) > 0 && Number(weakDiffAmount) > 0
        return hadThresholdVal ? weakAmountWithSymbol : ''
      }
       */
    },
    // // 是否是弱拦截
    // openWeakIntercept () {
    //   return this.cartAbtInfo?.LowpriceUser?.param?.ControlForcedInterception != 'on'
    // },
    checkoutNameText () {
      return this.template(this.controlCheckoutNameText, this.language?.SHEIN_KEY_PWA_24262)
    },
    buttonNameClickType () {
      /*
      let {
        ControlForcedInterception: interception = ''
      } = this.cartAbtInfo?.LowpriceUser?.param || {}
      let {
        strong_intercept_threshold = {},
        weak_intercept_threshold = {}
      } = this.cartInfo || {}
      let { usdAmount: strongUsdAmount = 0 } = strong_intercept_threshold || {}
      let { usdAmount: weakUsdAmount = 0 } = weak_intercept_threshold || {}
      */
      const { threshold_price } = this.cartInfo?.touch_rule_list?.find(i => i.hint_type == 5) || {}
      const amount = Number(threshold_price?.usdAmount || 0)
      if (this.openControlLowPriceUser) {
        // return interception == 'on' ? `power_checkout_${Number(strongUsdAmount)}` : `lower_checkout_${Number(weakUsdAmount)}`
        return this.openControlLowPriceUser == 1 ? `power_checkout_${amount}` : `lower_checkout_${amount}`
      }
      return 'checkout'
    },
    TaxIncludeInfo() {
      if((this.cartAbtInfo?.TaxSwitch?.param?.TaxSwitchValue || '')?.split(',')?.includes('cart') && this.totalPrice > 0) return this.taxInclusiveTip || ''
      return ''
    },
    payShowVisible() {
      return this.payShowStyle === 'off'
    },
    showQuickShip() {
      let checkedCarts = this.carts.filter(item => item.is_checked == 1)
      const isQuickShipCarts = checkedCarts.length ? checkedCarts.every(item => item.quick_ship == 1) : false
      return isQuickShipCarts && this.cartQsTagInfo.isShowNewStyle ? this.cartQsTagInfo.text : false
    },
    // 正常在售商品中是否有付费会员赠品
    hasClubGift() {
      const normalOnSaleCartList = this.carts.filter(item => !this.soldoutIds.includes(item.id)) // 正常在售商品
      return normalOnSaleCartList.some(item => item.isSheinClubGiftItem)
    },
    showPayBtn() {
      return this.showCartPayPalBtn || this.payShowStyleA
    },
    showCartPayPalBtn() {
      // TODO
      return this.showPaypalBtn && this.renderPaypalBtnStatus > 0
      // return true
    },
  },
  watch: {
    checkoutHeight () {
      this.checkoutHeightUpdate()
    },
    showDiscountDetails (n) {
      if(n && this.showIncentiveProductListDialog) {
        this.handleIncentiveProductListDialog(false)
      }
      this.updateState({
        key: 'checkoutMask',
        value: n || (!n && this.showIncentiveProductListDialog)
      })
    },
    showIncentiveProductListDialog(n) {
      if(n && this.showDiscountDetails) {
        this.updateState({
          key: 'showDiscountDetails',
          value: false
        })
      }
      this.updateState({
        key: 'checkoutMask',
        value: n || (!n && this.showDiscountDetails)
      })
      if (n) {
        typeof window != 'undefined' && document.body.classList.add('mshe-fixed')
      } else {
        typeof window != 'undefined' && document.body.classList.remove('mshe-fixed')
      }
    },
    totalPriceAmountWithSymbol(){
      this.handleCheckoutContentLength()
    },
    showNewFreeShipping: {
      handler(val) {
        this.checkoutFreeShippingExpose(val)
      },
      immediate: true,
    },
    showLowPriceUser (val) {
      if (val) this.loadLowPriceUser = true
    },
    filterType(newVal, oldVal) {
      const { CART_FILTER_CHECKOUT_TIP_SWITCH } = gbCommonInfo
      if(oldVal == 'none' && newVal != oldVal && CART_FILTER_CHECKOUT_TIP_SWITCH) {
        if(!this.filterLabelPopoverCache) this.filterLabelPopoverCache = new CheckoutTipCache()
        let memberId = UserInfoManager.get({ key: 'memberId', actionType: 'cart/filterType' })
        if(!memberId) return
        if(this.filterLabelPopoverCache.isExpireById(memberId)){
          this.setPopoverTypeWithPriority('filter')
          setTimeout(() => {
            this.setPopoverTypeWithPriority('')
          }, 3000)
          this.filterLabelPopoverCache.add(memberId)
        }
      }
    },
    showIncentivePopover(n) {
      if(n) {
        this.setPopoverTypeWithPriority('incentive')
      } else {
        this.setPopoverTypeWithPriority('')
      }
    },
    channelSessionParams: {
      handler(n, o) {
        if (typeof window == 'undefined') return
        if(n) {
          if(!o){
            this.initPaypalSDK()
          } else {
            if((n.shipCountry != o.shipCountry) || (n.orderCurrency != o.orderCurrency) || (n.orderDetails.length != o.orderDetails.length)) {
              this.initPaypalSDK()
            } else if(n.orderDetails.length == o.orderDetails.length){
              let diff = false
              for(let i = 0; i < n.orderDetails.length; i++) {
                let targetItem = o.orderDetails.find(item => (n.orderDetails[i].businessModel === item.businessModel && n.orderDetails[i].companyId === item.companyId))
                if(!targetItem) {
                  diff = true
                  break
                }
              }
              if(diff) {
                this.initPaypalSDK()
              }
            }
          }
        }
      },
      deep: true,
      immediate: true
    },
    showCartPayPalBtn: {
      handler(val) {
        if((typeof window != 'undefined') && val) {
          this.changeAsyncPaypalDialogLoadedStatus({
            show: true,
          })
        }
      },
      immediate: true,
    }
  },
  mounted () {
    this.isIPhoneX = isIPhoneX()
    this.handleCheckoutContentLength()
    this.initLowerPriceUserAbtTest()
    window.requestIdleCallback(this.handleDiscountDeatilsPrefetch)
  },
  updated () {
    if(this.batchActive) return
    // checkout 改变后，更新优惠券助手位置
    const checkoutHeight = $('.j-cart-checkout').height() || 0
    this.checkoutHeight = checkoutHeight
  },
  activated() {
    if(!this.isFirstRenderPaypal && this.showCartPayPalBtn && this.channelSessionParams) {
      this.initPaypalSDK()
    }
    this.checkoutFreeShippingExpose(this.showNewFreeShipping)
  },
  deactivated() {
    this.isExposedFreeShippingIcon = false
    this.hasCloseBNPLPopover = false
  },
  methods: {
    ...mapMutations('cart', ['updateState', 'toggleDrawer', 'changeAsyncPaypalDialogLoadedStatus']),
    ...mapActions('cart', ['fetchCartAbtInfo', 'fetchModifyCartCheckStatus', 'handleCartLogin', 'asyncUpdateCartInfo', 'checkSoldOut', 'fetchCarts']),
    ...mapMutations(['exchangingSpaScene', 'changeRootSLoadingStatus']),
    checkoutFreeShippingExpose(isVisble) {
      // 未离开页面，只会上报一次
      if (isVisble && !this.isExposedFreeShippingIcon) {
        this.isExposedFreeShippingIcon = true
        daEventCenter.triggerNotice({
          daId: '1-8-3-21'
        })
      }
    },
    handleCheckoutContentLength(){
      this.$nextTick(()=>{
        const ed = document.querySelector('.j-totalDiscountAmount .total-discount-txt') || {}
        const e = document.querySelector('.j-totalAmount .price-amount-decimal') || {}
        const withPrice = document.querySelector('.j-installmentPrice') || {}
        const normalPrice = document.querySelector('.j-normalPrice') || {}
        if (
          e.scrollHeight > (e.clientHeight + 1)
          || ed.scrollWidth > ed.clientWidth
          || withPrice.scrollWidth > withPrice.clientWidth
          || normalPrice.scrollWidth > normalPrice.clientWidth
        ) {
          this.fontSize = '12px'
        } else {
          this.fontSize = '14px'
        }
      })
    },
    showCouponHelper () {
      let { usableCouponList = [], disabledCouponList = [] } = this.couponInfo || {}
      const is_satisfied = usableCouponList.length > 0 && disabledCouponList.length > 0 ? 'part' 
        : usableCouponList.length > 0 ? 'all'
          : disabledCouponList.length > 0 ? 'none'
            : ''
      daEventCenter.triggerNotice({
        daId: '1-8-7-10',
        extraData: { is_satisfied },
      })
      this.toggleDrawer({ type: 'coupon-helper', value: true })
    },
    prefetchCheckout(step, paymentCode = '') {
      if (window.toCheckout) return
      if (step === 1) {
        if(this.prefetchCheckoutInfo || !this.cartCheckedNum || !this.loginStatus) return
        this.prefetchCheckoutInfo = prerenderCheckout.getCache(paymentCode)
      } else if (!window.checkoutJsPreloadOnce) {
        window.checkoutJsPreloadOnce = true
        // import( /* webpackChunkName: 'checkout_index' */ '../../checkout/container.vue')
        // loadComps('prefetch')
        prefetchResource.prefetchJs({
          prefetchList: [
            {
              chunkName: 'checkout_index',
              relType: 'preload'
            },
            {
              chunkName: 'checkout_top_part',
              relType: 'preload'
            },
            {
              chunkName: 'checkout_middle_part',
              relType: 'preload'
            },
          ]
        })
      }
    },
    clickCheckout ({ opt, paymentCode, foldPayment } = {}) {
      const prefetchInfo = this.prefetchCheckoutInfo
      this.prefetchCheckoutInfo = null
      if(this.lastCheckStockTime) {
        let now = new Date().getTime()
        let optAvgTime = 0
        if(window.cart_opt_time.length) {
          let optTotalTime = 0
          window.cart_opt_time.forEach(item => {
            optTotalTime += item
          })
          optAvgTime = Math.ceil(optTotalTime / window.cart_opt_time.length)
        }
        const data = [{
          key_path: 'checkout_diff_time',
          values: { num: now - this.lastCheckStockTime }
        }, {
          key_path: 'cart_opt_avg_time',
          values: { num: optAvgTime },
        }]
        window.TPM?.runImmediate({
          marketing: 'ClientAnalytics',
          method: '_defineTrack',
          params: { data: { data }, options: { immediate: true } }
        })
      }
      
      // 暂仅常规路径走预取
      this.goCheckout('conventional', { opt, prefetchInfo, paymentCode, foldPayment })
    },
    goCheckout (type, { opt, prefetchInfo, paymentCode = '', foldPayment, next } = {}) { // type: 'conventional' 正常，'guest' 游客
      if (window.toCheckout) return
      window.toCheckout = true
      web_checkout_button_click()
      if(!this.cartCheckedNum){
        this.$toast(this.hasClubGift ? this.language.SHEIN_KEY_PWA_31164 : this.language.SHEIN_KEY_PWA_20628)
        this.recordLog('1')
        return
      }
      this.handleDiscountDetails(false)
      if(this.showIncentiveProductListDialog) {
        if (this.incentivePopoverContent?.type === 'credit_reward') this.scene = 'clubpop'
        this.handleIncentiveProductListDialog(false)
      }
      let { langPath } = gbCommonInfo

      // 登录注册>闪购限购>限时折扣超限>打折超限 wiki.pageId=1137561716
      const validatorCallback = () => {
        // 打折限购以及闪购判断是否超过数量
        let LimitIds = [], limitTipData = [], newLimitTipData = []
        let promotionGroupList = this.carts.filter(item => item.promotionGroupId)
        // SHEIN打折限购只关注勾选的商品
        if (!this.IS_RW) promotionGroupList = promotionGroupList.filter(item => item.is_checked)
        promotionGroupList.forEach( cartItem => {
          const itemPromotionInfo = this.integrityPromotionInfo[cartItem.promotionGroupId]
          let result = handleOrderOrSingleIsMeetOrOver(cartItem, itemPromotionInfo, true)
          if(result && !LimitIds.includes(cartItem.promotionGroupId)){
            LimitIds.push(cartItem.promotionGroupId)
          }

          if (!this.IS_RW && result) {
            const content = itemPromotionInfo.content || {}
            const tip = (content.tip || '')?.replace(/\d+/g, val => `<span>${val}</span>`)
            const title = this.language.SHEIN_KEY_PWA_25486 || ''
            const limitTipItem = {
              tip: `<p style="text-transform: uppercase">${title}</p><div>${tip}</div>`,
              promotionGroupId: cartItem.promotionGroupId,
              goodsList: [cartItem],
              reason: result,
            }
            limitTipData.push(limitTipItem)
          }
        })
        
        if (!this.IS_RW && limitTipData.length) {
          // 将同活动类型的商品聚合
          limitTipData.forEach(item => {
            let promotionItem = newLimitTipData.find(child => child.promotionGroupId == item.promotionGroupId)
            if (promotionItem) {
              promotionItem.goodsList.push(...item.goodsList)
            } else {
              newLimitTipData.push(item)
            }
          })
        }

        // 常规闪购>新人闪购>特殊闪购>专区闪购>直播闪购>打折限购>品牌特卖
        // 品牌特卖也属于闪购，由于要脱离基于 flash_type 的排序逻辑, 故单独放在 brandSaleOverTip 里，手动排序到最后
        let flashSaleOverTip = []
        let brandSaleOverTip = []
        Object.keys(this.flashSaleGroupList).forEach(flash_promotion_id => {
          let flashSalePro = this.integrityPromotionInfo[flash_promotion_id] || {}
          let flashSaleProGroupList = flashSalePro.limit_mode == 0 ? this.flashSaleGroupList[flash_promotion_id]?.filter(item => item.is_checked) || [] : []
          if(flashSaleProGroupList.length) {
            let { result, flashSaleAndDiscountLimitHandleInfo } = handleOrderOrSingleIsMeetOrOver(null, { ...flashSalePro, list: flashSaleProGroupList }, true, true)
            if(result){
              let goodsList = flashSaleProGroupList
              if(result == 1) {
                let singleOverGoodSn = Object.entries(flashSaleAndDiscountLimitHandleInfo?.skcLimitNum || {}).filter(([ , count]) => count > (flashSalePro?.cart_limit?.single || Number.MAX_SAFE_INTEGER)).map(i => i[0])
                goodsList = flashSaleProGroupList.filter(item => singleOverGoodSn.includes(item.product.goods_sn))
              }
              // flashSaleType 将在后续用来排序, 及埋点, 混合了直播闪购后,按直播闪购的优先级,此处填入直播闪购的type_id:11, 与普通闪购type 1, 2, 3, 11 刚好满足排序
              const item = {
                flashSaleType: flashSalePro.type_id == 10 ? flashSalePro.flash_type : flashSalePro.type_id,
                tip: flashSalePro.content.tips.list[result],
                goodsList,
                reason: result,
                promotion_id: flash_promotion_id,
              }
              // 抽离 品牌特卖 活动单独处理
              if (item.flashSaleType == 6) {
                brandSaleOverTip.push(item)
              } else {
                flashSaleOverTip.push(item)
              }
            }
          }
        })
        flashSaleOverTip.sort((a, b) => {
          // 将专区闪购的type 4, 5 转换为 4, 以便排序
          const m = a.flashSaleType == 4 || a.flashSaleType == 5 ? 4 : a.flashSaleType
          const n = b.flashSaleType == 4 || b.flashSaleType == 5 ? 4 : b.flashSaleType
          let result = m - n
          if(result == 0 && m == 4) { // 专区闪购内部, 按照promotion_id升序排序
            result = Number(a.promotion_id) - Number(b.promotion_id)
          }
          return result
        })
        if(flashSaleOverTip.length || brandSaleOverTip.length){
          this.recordLog('4')
          if(!this.IS_RW) {
            this.flashSaleOverTip = [...flashSaleOverTip, ...newLimitTipData, ...brandSaleOverTip]
            this.showFlashSaleTip = true
            this.emitLimitAnlysis([...flashSaleOverTip, ...brandSaleOverTip], newLimitTipData)
          } else { // romwe走旧的拦截逻辑, template中同样需要进行判断
            if(flashSaleOverTip.length + brandSaleOverTip.length > 1) {
              this.flashSaleOverTip = [...flashSaleOverTip, ...brandSaleOverTip]
              this.showFlashSaleTip = true
            } else {
              this.$toast({
                htmlString: `<div style="text-align: center">${(flashSaleOverTip[0] || brandSaleOverTip[0]).tip}</div>`,
                duration: 4000
              })
            }
          }

          daEventCenter.triggerNotice({
            daId: '1-8-2-12',
            extraData: {
              is_flashsale: [...flashSaleOverTip, ...brandSaleOverTip].map(item => item.flashSaleType).join(','),
            }
          })
          this.emitInitCheckout({ value: 0, msg: 'over', reason: 3, scenes: type, paymentMethod: paymentCode })
          return
        }

        // SHEIN打折限购超限展示弹窗
        if (!this.IS_RW && limitTipData.length) {
          this.recordLog('6')
          this.flashSaleOverTip = newLimitTipData
          this.showFlashSaleTip = true
          this.emitLimitAnlysis([], newLimitTipData)
          return
        }
        // ROMWE打折限购超限促销活动头抖动
        if (this.IS_RW && LimitIds.length){
          this.recordLog('6')
          cartEmphasizePromotionAll(LimitIds, { shake: true, changeColor: true, scroll: true })
          this.updateState({ key: 'filterType', value: 'none' })
          this.emitInitCheckout({ value: 0, msg: 'over', reason: 3, scenes: type })
          return
        }

        let underPriceProducts = []
        Object.values(this.underPriceGroupList)?.forEach(item => {
          underPriceProducts = underPriceProducts.concat(item)
        })
        let underPriceOverItem = underPriceProducts.find(cartItem => cartItem.is_checked == 1 && (cartItem.product.product_promotion_info?.some(promo => promo.single_over == 1)))
        if(underPriceOverItem) {
          this.recordLog('7')
          this.$toast( this.underPriceAbtValue ? this.language.SHEIN_KEY_PWA_23543 : this.language.SHEIN_KEY_PWA_23542, 3000)
          this.updateState({ key: 'filterType', value: 'none' })
          this.emitInitCheckout({ value: 0, msg: 'over', reason: 3, scenes: type })
          return
        }

        return true
      }
      
      if ( type == 'conventional' ) {
        let prime_product_code = ''
       
        this.queryStock((outStock) =>{
          if (!validatorCallback()) {
            return
          }
          if(outStock) {
            this.updateState({
              key: 'soldOutDialogShow',
              value: true
            })
            this.emitInitCheckout({ value: 0, msg: 'out of stock', reason: 2, scenes: type })
          } else {
            if (this.openControlLowPriceUser == 1) {
              this.recordLog('8')
              // 低客单强拦截
              this.emitInitCheckout({ value: 0, scenes: type, reason: 4, msg: 'UnArrived Low Price' })
              this.lowerPriceParams = {}
              this.updateState({
                key: 'showLowPriceUser',
                value: true
              })
            } else if (this.openControlLowPriceUser == 2 && opt != 'lowerPriceUser') {
              this.recordLog('8')
              // 低客单弱拦截, opt避免二次弱拦截
              this.emitInitCheckout({ value: 0, scenes: type, reason: 4, msg: 'UnArrived Low Price' })
              this.lowerPriceParams = {}
              this.updateState({
                key: 'showLowPriceUser',
                value: true
              })
            } else {
              this.recordLog('0')
              let checkoutPath = `${ langPath }/checkout?_b=m${prime_product_code ? '&prime_product_code=' + prime_product_code : ''}${getAutoCouponCodes(this.cartInfo.coupon_infos)}${getPaymentCode(paymentCode)}${getFoldPayment(foldPayment)}`
              function goToCheckout() {
                let browserRule = getBrowserName()
                if(browserRule == 'Apple Safari'){
                  let endTid = setTimeout(function () {})
                  let  _setTimeout = window.setTimeout
                  window.setTimeout = (fn, time) => {
                    return _setTimeout(fn, time ? 10 : 0)
                  }
                  // 清除定时器对跳转下单页性能影响
                  for (let i = endTid; i >= 0; i--) {
                    clearTimeout(i)
                    clearInterval(i)
                  }
                }
                window.location.href = checkoutPath
              }
              
              if(gbCommonInfo.CHECKOUT_PRERENDER_SWITCH === 'off') {
                goToCheckout()
              } else {
                window.checkoutPending = (prefetchInfo || prerenderCheckout.getCache(`${paymentCode}${getFoldPayment(foldPayment)}`)).then((res) => {
                  return new Promise(resolve => {
                    let { contextForSSR, redirectUrl, code } = res || {}
                    if (redirectUrl) {
                      // pwa返回跳转优化
                      if (code == '300206') {
                        window.location = langPath + '/user/logout'
                      } else {
                        if(redirectUrl.indexOf('?') > -1) {
                          redirectUrl = redirectUrl + getFoldPayment(foldPayment)
                        } else {
                          redirectUrl = redirectUrl + getFoldPayment(foldPayment, true)
                        }
                        window.location.href = redirectUrl
                      }
                      return
                    }
                    contextForSSR ? resolve(res) : goToCheckout()
                  })
                }).catch(e => Promise.reject(e?.code === 'ERR_CANCELED' ? () => {} : goToCheckout))
                next ? next() : this.$routerPush(checkoutPath)
              }
              let simpleCart = []
              this.cartInfo.carts.forEach((item, index) => {
                let obj = {
                  product: {
                    goods_sn: item.product.goods_sn,
                    goods_id: item.product.goods_id,
                    productRelationID: item.product.productRelationID,
                    brand: item.product.brand,
                    cat_id: item.product.cat_id,
                    sale_discount: item.product.sale_discount,
                    size: item.product.size
                  },
                  quantity: item.quantity,
                  unitPrice: item.product?.feSalePrice,
                  attr_value_en: item.attr_value_en,
                  index: index
                }
                simpleCart.push(obj)
              })
              let tpmCacheCart = {
                carts: simpleCart,
                currency: this.cartInfo.env.Currency,
                totalPrice: this.cartInfo.totalPrice
              }

              sessionStorage.setItem('tpmCacheCart', JSON.stringify(tpmCacheCart))
              this.emitInitCheckout({ value: 1, scenes: type, notSendTpm: true })
            }
          }
        }, true)
      }
    },
    emitLimitAnlysis(flashList = [], limitList = []) {
      const flashReasonList = flashList.map(i => `${i.flashSaleType > 10 ? i.flashSaleType : 10}\`${i.flashSaleType > 10 ? '-' : i.flashSaleType}\`${i.reason > 2 ? 2 : i.reason}`)
      const limitReasonList = limitList.map(i => `24\`-\`${i.reason}`)
      daEventCenter.triggerNotice({
        daId: '1-8-2-22',
        extraData: {
          reason: [...flashReasonList, ...limitReasonList].join(',')
        }
      })
    },
    emitInitCheckout ({ value = 0, msg = '', scenes = '', reason = '', options = {}, notSendTpm = false, paymentMethod = '' }) {
      const returnCouponItem = this.addOnPromotionList?.find((v) => [20, 21].includes(+v.typeId))
      daEventCenter.triggerNotice({
        daId: '1-8-2-3',
        extraData: {
          carts: this.carts,
          value,
          msg,
          scenes,
          cartInfo: this.cartInfo,
          mallShippingAnalysisParam: this.mallShippingAnalysisParam,
          cartPromotionMap: this.cartCheckoutPromotionMap,
          isPlatform: this.isPlatForm,
          showQuickShip: this.showQuickShipTag,
          over_type: this.flashSaleOverLimitType,
          reason: reason,
          prime_flag: '-',
          abtest: this.abtTest?.sa || '',
          button_name: this.buttonNameClickType,
          click_from: 1,
          status: this.openControlLowPriceUser ? 0 : 1,
          coupon_change: '-',
          coupon_satisfied: this.couponInfo?.usableCouponList?.length > 0 ? 1 : 0,
          ...(options || {}),
          ...(this.lowerPriceParams || {}),
          notSendTpm,
          payment_method: paymentMethod ? this.paymentFrontPaymentList.map(item => item.paymentCode.toLowerCase()).join(',') : 'normal',
          is_couponbag: returnCouponItem ? (returnCouponItem.couponNum > 1 ? 1 : 0) : '-',
          label_id: !isNaN(this.filterType)
            ? this.goodsFilterLabels.find(i => this.filterType == i.id)?.couponType
            : this.filterType,
          scene: this.scene,
        }
      })
    },
    queryStock(cb, needLoginStatus = true) {
      if(!this.loginStatus && needLoginStatus) {
        this.handleLoginEvent(needLoginStatus)
        this.recordLog('2')
        return
      }
      
      if(this.lastCheckStockTime) {
        let { timeLimit = 120000, rowsLimit = 80 } = gbCommonInfo.CHECK_CART_STOCK_CONFIG || {}
        let now = new Date().getTime()
        let checkedCarts = this.carts.filter(item => item.is_checked == 1)
        if(timeLimit && rowsLimit && (now - this.lastCheckStockTime < timeLimit) && (checkedCarts.length <= rowsLimit)) { // 性能优化：时间戳差值小于120秒且勾选行数小于等于80则不检查库存接口
          cb && cb()
          return
        }
      }
      this.changeRootSLoadingStatus({
        show: true,
        maskTransparent: true,
      })
      checkStock({ needLoginStatus }).then( async (res) => {
        this.changeRootSLoadingStatus({
          show: false,
        })
        if(res.code == 0){
          const { checked_num, exceed_cart_num, limit_checked_num } = res.info
          if(exceed_cart_num > 0){
            this.recordLog('3')
            this.$toast(
              this.template(limit_checked_num, checked_num - limit_checked_num, this.language.SHEIN_KEY_PWA_24132),
              4000
            )
            daEventCenter.triggerNotice({
              daId: '1-8-2-13'
            })
            apiReport.report({
              apiPath: '/cart/checkout/billing_overrun'
            })
          } else {
            let outStockCarts = res?.info?.outStockCarts || []
            if(outStockCarts.length) {
              this.checkSoldOut({ outStockCarts: outStockCarts })
              this.recordLog('3')
            } else {
              this.updateState({ key: 'lastCheckStockTime', value: Date.now() })
            }
            cb && cb(outStockCarts.length)
          }
        } else {
          if(res.code == -1 && res.needLogin) {
            this.handleLoginEvent(needLoginStatus)
            this.recordLog('2')
          } else {
            this.recordLog('3')
            this.emitInitCheckout({ value: 0, msg: res.msg, scenes: 'conventional' })
            if (['500302', '500306', '300417', '500303'].includes(res.code)) {
              this.$toast(this.template(res?.info?.limitCount, res?.info?.resultLimit, res?.info?.remainNum, res.tips), 4000)
            } else {
              res.tips && this.$toast(res.tips)
            }
          }
        }
      }).catch((err) => {
        this.changeRootSLoadingStatus({
          show: false,
        })
        console.log(err)
        cb && cb()
      })
    },
    async handleLoginEvent(needLoginStatus){
      this.emitInitCheckout({ value: 0, msg: 'Not login', reason: 1, scenes: needLoginStatus ? 'conventional' : 'guest' })
      
      const { res } = await this.handleCartLogin({ type: 'checkout' })

      if(res.code == 0){
        let browserRule = /^.*((iPhone)|(iPad)|(Safari))+.*$/
        if(browserRule.test(navigator.userAgent)){
          await this.asyncUpdateCartInfo({ cartInfo: res.info })
        } else {
          this.asyncUpdateCartInfo({ cartInfo: res.info })
        }
      }
      this.clickCheckout()
    },
    initLowerPriceUserAbtTest () {
      let lowPriceUser = this.cartAbtInfo?.LowpriceUser || {}
      // eslint-disable-next-line @shein-aidc/abt/abt
      let abtTest = abtservice.getUserAbtResultForAnalysis({ abtInfo: { 'LowpriceUser': lowPriceUser } })
      this.abtTest = abtTest || {}
    },
    clickMask () {
      this.handleDiscountDetails(false)
    },
    handleDiscountDetails (val) {
      if(!this.isShowDiscountDetailsEntry) return
      this.updateState({
        key: 'showDiscountDetails',
        value: val
      })
      daEventCenter.triggerNotice({
        daId: '1-8-2-2',
        extraData: {
          is_open: Number(val)
        }
      })
    },
    handleIncentiveProductListDialog (val) {
      this.updateState({
        key: 'showIncentiveProductListDialog',
        value: val
      })
      if(!val) {
        if(this.showIncentiveTimes < 3  && (this.inducementSortList?.length || 0) - 1 >= this.showIncentiveTimes) {
          if(this.incentivePopoverSwitchTimer) {
            clearTimeout(this.incentivePopoverSwitchTimer)
            this.updateState({
              key: 'incentivePopoverSwitchTimer',
              value: null
            })
          }
          const incentiveGapTime = this.incentiveTimeConfig?.incentiveGapTime || 1000 * 60
          this.updateState({
            key: 'incentivePopoverSwitchTimer',
            value: setTimeout(() => {
              this.updateState({
                key: 'showIncentivePopover',
                value: true
              })
            }, incentiveGapTime)
          })
        }
      }
    },
    checkoutHeightUpdate () {
      // checkout 改变后，更新优惠券助手位置
      this.$nextTick(() => {
        const checkoutHeight = this.checkoutHeight
        const couponHelperHeight = 36
        $('.j-cart-container').css({ paddingBottom: checkoutHeight + 10 + couponHelperHeight + 10 })
      })
    },
    compare(p, lth = true){ //这是比较函数
      return (m, n) => {
        let a = m[p]
        let b = n[p]
        return lth ? a - b : b - a // a-b 升序 b-a 降序
      }
    },
    emitClickCheckoutEvt ({ value = 0, msg = '', scenes = '', reason = '', options = {} }) {
      this.emitInitCheckout({ value, msg, scenes, reason, options })
    },
    goCheckoutEvt ({ click_type = '', click_from = '', click_status = '' } = {}) {
      if (click_type == 'lowerPriceUser') {
        this.lowerPriceParams = { click_from, status: click_status }
        this.clickCheckout({
          opt: click_type
        })
      }
    },
    recordLog(type){
      if(type != '0') {
        window.toCheckout = false
      }
      web_checkout_button_jump(type)
    },
    template: template,
    // 触发超限弹窗后，如果在筛选状态下且当前点击商品不属于当前筛选标签，则清空筛选状态
    handleFilter(item) {
      if (this.filterType !== 'none' && !showFilterItem(item, this.filterType, this.targetCouponInfo, this.goodsFilterLabels)) {
        this.updateState({ key: 'filterType', value: 'none' })
      }
    },
    updateCheckedItemIds(val) {
      if(val) {
        this.checkedItemIds = val
        return
      }
      this.checkedItemIds = this.cartsId.filter(id => this.cartItemMap[id]?.is_checked)
    },
    handleDiscountDeatilsPrefetch() {
      prefetchResource.prefetchJs({
        prefetchList: [
          {
            chunkName: 'cart-checkout-discount-details',
            relType: 'prefetch'
          },
        ]
      })
    },
    setPopoverTypeWithPriority(value) {
      if(value == '' || this.checkoutButtonPopoverType == '') {
        this.checkoutButtonPopoverType = value
        return
      }
      const Priority = ['newUser', 'incentive', 'filter']
      let targetOrder = Priority.indexOf(value)
      let currentOrder = Priority.indexOf(this.checkoutButtonPopoverType)
      if(targetOrder >= 0 && targetOrder < currentOrder) {
        this.checkoutButtonPopoverType = value
      }
    },
    // paypal email快速注册/登录后，跳转下单页
    async goCheckoutPageByPaypal() {
      // 登录后重新查车
      await this.fetchCarts()
      this.updateState({
        key: 'loginStatus',
        value: true
      })
      // 跳转到下单页
      this.clickCheckout({ paymentCode: 'PayPal-GApaypal', foldPayment: true })
    },
    // 初始化paypalSDK
    initPaypalSDK() {
      this.isFirstRenderPaypal = false
      const data = {
        ...this.channelSessionParams,
        orderAmount: this.totalPrice
      }
      // const data = {
      //   shipCountry: 'us',
      //   orderAmount: '100',
      //   orderCurrency: 'USD',
      //   payMethodList: ['PayPal-GApaypal'],
      //   orderDetails: [{ businessModel: 0, companyId: 1 }]
      // }
      // res: paypalSDK返回的email是否已注册账户接口的返回结果
      // email: paypalSDK返回的email账户
      PaypalSDK.getClientToken(data, (res, email) => {
        // 从paypal渠道页返回到shein 购物车页面，paypal正常回传邮箱，接口被拦截（risk_decision=2）
        if(res?.info?.risk_decision == 2) {
          // 弹出失败弹窗，弹窗内点击“更换方式注册”按钮，点击进入登录注册页面，或者点击取消弹窗消失
          this.updateState({
            key: 'showRegisterFailedDialog',
            value: true
          })
          return
        } else if(res?.info?.risk_decision == 1) {
          // 从paypal返回到shein 购物车页面，paypal正常回传邮箱，接口被放行（risk_decision=1）
          // 当前用户未注册，判断paypalSDK返回的email账户是否已在shein注册
          if(res?.info?.is_existed === 0) {
            // 未注册：弹出确认使用paypalSDK返回的email进行快速注册
            this.updateState({
              key: 'emailConfirmDialogInfo',
              value: { email }
            })
            this.updateState({
              key: 'showEmailConfirmDialog',
              value: true
            })
          } else if(res?.info?.is_existed === 1) {
            // 已注册：跳转至登录页
            SHEIN_LOGIN && SHEIN_LOGIN.show({
              show: true,
              instance: 'paypalCheckout',
              action: 'switch_account',
              bi: 'paypal_checkout',
              from: 'paypal_checkout',
              switchAccountVal: {
                accountType: res?.info?.loginAccountType || 7,
                accountAlias: email
              },
              cb: async () => {
                // 登录成功，跳转下单页
                this.goCheckoutPageByPaypal()
              }
            })
          }
        }
      }, (renderPaypalBtnStatus) => {
        this.updateState({
          key: 'renderPaypalBtnStatus',
          value: renderPaypalBtnStatus ? 1 : 0
        })
      }, () => {
        if(!this.cartCheckedNum){
          this.$toast(this.hasClubGift ? this.language.SHEIN_KEY_PWA_31164 : this.language.SHEIN_KEY_PWA_20628, 3000)
          return { show: false }
        }
        return { show: true, orderAmount: this.totalPrice }
      })
    },
    openCheckoutDiscount(type) {
      this.handleDiscountDetails(true)
      this.autoHighlightDisicountType = type
    },
    onAfterEnter() {
      if (this.autoHighlightDisicountType) {
        this.$refs.discountDetailsV2?.showHighlightDiscount(this.autoHighlightDisicountType)
        this.autoHighlightDisicountType = ''
      }
    }
  }
}
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.vue-slide-layer-bottom-fade-enter-active,
.vue-slide-layer-bottom-fade-leave-active {
  transition: all 0.3s;
}

.vue-slide-layer-bottom-fade-enter,
.vue-slide-layer-bottom-fade-leave-active {
  transform: translate3d(0,100%,@tranlatez-transform1)!important; /* stylelint-disable-line declaration-no-important */
}
.cart-checkout {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 19; /* stylelint-disable-line declaration-property-value-blacklist */
}
.c-coupon-helper-bubble{
  max-width: 92%;
  position: absolute;
  top: -1.09333rem;
  .left(0.2rem);
  margin-top: -0.415rem;
  display: inline-block;
  text-align: center;
  // position: fixed;
  .margin-l(.2rem);
  margin-bottom: .2rem;
  // bottom: 3.5rem;
  z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
  border-radius: 9999px;
  color: #fff;
  transition: max-width linear .5s;
  overflow: hidden;
  white-space: nowrap;
  background-clip: padding-box;
  vertical-align: middle;
  box-sizing: content-box;
  i{
    display: inline-block;
    line-height: 64px;
    width: 64px;
    [data-dpr='1'] & {
      line-height: 64px * 0.5;
      width: 64px * 0.5;
    }
    [data-dpr='3'] & {
      line-height: 64px * 1.5;
      width: 64px * 1.5;
    }
    text-align: center;
    color: #fff;
    font-size: 16px;
  }
  span{
    font-weight: bold;
    display: inline-block;
    .margin-l(-.18rem);
    .margin-r(.24rem);
    transition: all linear .5s;
  }
  &.bubble-fold{
    max-width: 64px;
    [data-dpr='1'] & {
      max-width: 64px * 0.5;
    }
    [data-dpr='3'] & {
      max-width: 64px * 1.5;
    }
    span{
      color: transparent;
    }
  }
}
.recommend-guide-a {
  position: absolute;
  top: -116/75rem;
}
.is-old-style {
  background: hsla(0, 0%, 0%, 0.6);
  max-width: 5.6rem;
  border: 1px solid rgba(255,255,255,.6);
}

.c-cart-checkout{
  position: relative;
  .border-dpr(border-top,2px,#e5e5e5);
  background: #fff;
  padding: .32rem;
  overflow: hidden;
  &.new-checkout{
    padding: 16/75rem 24/75rem;
  }
}

.price-vat {
  font-size: 12px;
  line-height: 1;
  color: @sui_color_gray_dark2;
}

.new-checkout-btn{
  display: flex!important;/* stylelint-disable-line declaration-no-important */
  .checkout-txt{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    box-sizing: border-box;
  }
  .checkout-num{
    flex: 1;
    text-transform: capitalize;
  }
}

.cart-checkout__memberLimit {
  color: @color_red_dark;
  text-align: center;
}

.cart-checkout__title {
  display: flex;
  justify-content: space-between;
  margin-bottom: .32rem;
}
.cart-checkout__right {
  .txt-r();
}
.cart-checkout__price{
  flex: 1;
  text-align: right;
}
.cart-checkout__price-title {
  .font-dpr(24px);
}
.cart-checkout__price-text {
  font-weight: bold;
  .font-dpr(28px);
  &.discount{
    color: @sui_color_discount;
  }
}
.cart-checkout__price-origin{
  font-size: 12px;
  color: #959595;
  text-decoration:line-through;
  display: block;
}
.cart-checkout__price-gst {
  .font-dpr(24px);
  color: #999;
}

.cart-checkout__freeShipping{
  padding-top: 0.08rem;
  .font-dpr(20px);
  color: @sui_color_safety;
  .txt-r();
  strong {
    font-weight: normal;
  }
}
.cart-checkout__button{
  text-transform: uppercase;
  margin-top: 0.2133rem;
}

.cart-checkout__tip-coupon {
  .font-dpr(24px);
  color: #767676;
  text-align: center;
  margin-top: 0.32rem;
  strong {
    color: #101010;
    font-weight: normal;
  }
}

/* Discount Details 促销明细
----------------------------------------------------------------*/
.cart-checkout__DiscountDetails-entry {
  .font-dpr(24px);
  color: @color_red_dark;
  [class*="iconfont"] {
    color: @color_red_dark;
  }
}
.cart-checkout__DiscountDetails-arrow {
  display: inline-block;
  transform: rotate(180deg);
  transition: transform 0.2s linear;
  width: 12px;
  height: 12px;
  &.active {
    transform: rotate(0deg);
  }
  &.arrow-color{
    color: @sui_color_discount;
  }
}
.satisfy-free_tip {
  text-align: center;
  padding: 0.32rem;
  background: @sui_color_safety_bg;
  color: @sui_color_safety;
}

.cart-checkout-banner{
  display: flex;
  align-items: center;
}
.flashSale-over-tip{
  text-align: left;
  .tip-item{
    margin-top: 18/75rem;
  }
}

/*
  normal Bar 动画 
*/
/* 进场动画 */
.normal-bar-enter-active {
  transition: all 0.3s;
  transform: translate3D(0, -100%, 0);
}
.normal-bar-enter-to {
  transform: translate3D(0, 0, 0);
}
/* 离场动画 */
.normal-bar-leave-from {
  position: absolute;
  left: 12/37.5rem;
  right:12/37.5rem;
  z-index: -1;
}
.normal-bar-leave-active {
  position: absolute;
  left: 12/37.5rem;
  right: 12/37.5rem;
  transform: translate3D(0, 0, 0);
  transition: all 0.3s;
}
.normal-bar-leave-to {
  transform: translate3D(0, -100%, 0);
}

/*
  pnpl Bar 动画 
*/
/* 进场动画 */
.bnpl-bar-enter {
  transform: translate3D(0, 100%, 0);
}
.bnpl-bar-enter-active {
  transition: all .3s;
}
.bnpl-bar-enter-to {
  transform: translateY(0);
}
/* 离场动画 */
.bnpl-bar-leave-active {
  position: absolute;
  left: 12/37.5rem;
  right: 12/37.5rem;
  transform: translate3D(0, 0, 0);
  transition: all 1s;
}
.bnpl-bar-leave-to {
  transform: translate3D(0, 100%, 0);
}

/**
 pnpl popover 动画
 */
.cart-checkout-bnpl__popover-wrap {
  height: 36/37.5rem;
  overflow: hidden;
}
.bnpl-popover-enter, .bnpl-popover-leave-to {
  height: 0;
  .cart-checkout-bnpl-popover {
    transform: translate3d(0, 100%, 0);
  }
}
.bnpl-popover-enter-active,
.bnpl-popover-leave-active, .cart-checkout-bnpl-popover {
  transition: all .3s;
}
.bnpl-popover-enter-to,
.bnpl-popover-leave {
  height: 36/37.5rem;
  .cart-checkout-bnpl-popover {
    transform: translate3d(0, 0, 0);
  }
}

/**
  折扣详情动画
 */
.discount-details-enter,
.discount-details-leave-active {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translate3d(0, 100%, 0);
}
.discount-details-enter-active,
.discount-details-leave-active {
  transition: all 0.3s;
}
.discount-details-enter-to,
.discount-details-leave {
  transform: translate3d(0, 0, 0);
}
/* 低效用户推荐指引气泡动画 */
.recommend-guide-fade-enter-active,
.recommend-guide-fade-leave-active {
  transition: opacity 300ms,
}
.recommend-guide-fade-enter,
.recommend-guide-fade-leave-to {
  opacity: 0;
}
</style>
