<template>
  <div class="cart-bar-normal">
    <CartCheckoutAll :update-checked-item-ids="updateCheckedItemIds" />
    <CartCheckoutPrice
      :is-opened="!!showDiscountDetails"
      @click="handleDiscountDetails(!showDiscountDetails, 'click-save')"
    />
    <CartCheckoutButtonPopover
      :show-filter-label-popover.sync="showFilterLabelPopover"
    >
      <s-button
        v-enterkey
        :type="['primary','H80PX']"
        tabindex="0"
        :max-width="362/75+'rem'"
        role="button"
        :class="[
          'newcomer-guide__checkout',
          'new-checkout-btn',
          'fsp-element',
          incentiveBtnType ? 'incentive-button' : ''
        ]"
        style="text-transform: uppercase;"
        fsp-key="cart_checkout_btn"
        :style="{fontSize: fontSize}"
        :limit-duration="10"
        @pointerover.native="prefetchCheckout(1)"
        @pointerdown.native="prefetchCheckout(2)"
        @click="clickCheckout"
      >
        <div class="checkout-btn-content">
          <template v-if="controlCheckoutNameText">
            <span class="checkout-txt">{{ checkoutNameText }}</span>
          </template>
          <template v-else>
            <span class="checkout-txt">{{ language.SHEIN_KEY_PWA_15062 }}</span><span class="checkout-num">{{ checkedQuantity }}</span>
          </template>
        </div>
        <CheckoutButtonIncentiveText
          class="not-fsp-element"
          :controlCheckoutNameText="controlCheckoutNameText"
        />
      </s-button>
      <AutoStretchSwiperTag
        class="cart-bar-normal__swiper-tag"
        :show-free-shipping-tag="showNewFreeShipping"
        :show-quick-ship-tag="showQuickShip"
        stretch-direction="rtl"
        :language="language"
      />
    </CartCheckoutButtonPopover>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CartCheckoutAll from './CartCheckoutAll.vue'
import CartCheckoutPrice from './CartCheckoutPrice.vue'
import CartCheckoutButtonPopover from './CartCheckoutButtonPopover.vue'
import AutoStretchSwiperTag from './AutoStretchSwiperTag.vue'
import CheckoutButtonIncentiveText from './cartCheckoutIncentive/CheckoutButtonIncentiveText.vue'

/**
 * @description 购物车/结算BAR/普通结算
 */
export default {
  name: 'CartBarNormal',

  components: {
    CartCheckoutAll,
    CartCheckoutPrice,
    CartCheckoutButtonPopover,
    AutoStretchSwiperTag,
    CheckoutButtonIncentiveText,
  },

  props: {
    updateCheckedItemIds: {
      type: Function,
      required: true
    },
    handleDiscountDetails: {
      type: Function,
      required: true,
    },
    totalPriceAmountWithSymbol: {
      type: String,
      required: true
    },
    isShowDiscountDetailsEntry: {
      type: Boolean,
      required: true,
    },
    showFilterLabelPopover: {
      type: String,
      required: true,
    },
    prefetchCheckout: {
      type: Function,
      required: true,
    },
    fontSize: {
      type: String,
      required: true,
    },
    checkoutNameText: {
      type: String,
      required: true,
    },
    checkedQuantity: {
      type: null,
      required: true,
    },
    controlCheckoutNameText: {
      type: null,
      required: true,
    },
    showNewFreeShipping: {
      type: Boolean,
      required: true,
    },
    clickCheckout: {
      type: Function,
      required: true,
    },
    iconPrefix: {
      type: String,
      required: true,
    },
    language: {
      type: Object,
      required: true,
    },
    showQuickShip: {
      type: [Boolean, String],
      default: false,
    },
  },
  computed: {
    ...mapState('cart', ['showDiscountDetails']),
    ...mapGetters('cart', ['incentiveBtnType']),
  }
}
</script>

<style lang="less">
.cart-bar-normal {
  display: flex;
  align-items: center;
  background-color: #fff;

  .S-checkbox {
    margin-right: 5/37.5rem !important;/* stylelint-disable-line declaration-no-important */
    .S-checkbox__label {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap
    }
  }
  .cart-checkout__price-wrapper {
    margin-right: 12/75rem;
    flex: 1;
    text-align: right;
    overflow: hidden;
    max-width: 100%;
  }
  .cart-bar-normal__swiper-tag {
    position: absolute;
    right: -10/75rem;
    top: -14/75rem;
    .font-dpr(16px);
    &::after{
      content: "";
      width: 0;
      height: 0;
      border-color: #16533A transparent;
      border-style: solid;
      position: absolute;
      top: 100%;
      display: block;
      border-width: 10/75rem 10/75rem 0 0;
      right: 0
    }
  }
}
</style>

<style lang="less" scoped>
.incentive-button {
  flex-direction: column;
  line-height: normal !important;/* stylelint-disable-line declaration-no-important */
  align-items: center;
  justify-content: center;
  gap: 4/74rem;
}
.checkout-btn-content {
  display: flex!important;/* stylelint-disable-line declaration-no-important */
  width: 100%;
  justify-content: center;
  .checkout-txt{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    box-sizing: border-box;
  }
  .checkout-num{
    flex: unset;
    text-transform: unset;
  }
}
</style>
