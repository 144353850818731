<template>
  <div
    class="mcart-item"
    da-expose-code="page-cart-save-all-expose-events"
    :class="{'platform-save-all':isPlatForm}"
  >
    <div tabindex="0">
      {{ language.SHEIN_KEY_PWA_31159 }}
    </div>
    <div
      v-if="loginStatus && !batchActive"
      v-enterkey
      class="save-all j-animation-shake"
      tabindex="0"
      :data-soldOutGoodsNum="soldoutIds.length"
      da-event-click="1-8-7-32"
      da-event-expose="1-8-7-33"
      @click="handleSaveAll()"
    >
      {{ language.SHEIN_KEY_PWA_15791 }}
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { batchWishGoods } from '../utils/fetcher'
import { daEventCenter } from '../../../services/eventCenter'
import { daEventCenter as daEventCenterV2 } from '@shein-aidc/basis-sa-event-center'
import { template } from '@shein/common-function'

export default {
  data(){
    return {
      daEventExpose: null
    }
  },
  computed: {
    ...mapState('cart', ['language', 'soldoutIds', 'loginStatus', 'batchActive', 'isPlatForm'])
  },
  watch: {
    '$route' () {
      this.daEventExpose?.reset?.('page-cart-save-all-expose-events')
      this.$nextTick(() => {
        this.daEventExpose?.update?.('page-cart-save-all-expose-events')
      })
    }
  },
  mounted () {
    this.exposeEvents()
  },
  methods: {
    ...mapMutations('cart', ['updateState', 'handleModal']),
    ...mapActions('cart', [ 'asyncUpdateCartInfo']),
    handleSaveAll () {
      this.handleModal({
        text: this.language.SHEIN_KEY_PWA_17954,
        okCallback: () => {
          this.delAllSoldOut()
        },
        cancelCallback: () => {
        },
        cancelDaId: '1-8-7-19'
      })
    },
    delAllSoldOut () {
      var self = this
      this.updateState({
        key: 'loadingShow',
        value: true
      })
      batchWishGoods({ ids: self.soldoutIds })
        .then(res => {
          self.updateState({
            key: 'loadingShow',
            value: false
          })
          if (res.code == 0) {
            self.$toast(self.language.SHEIN_KEY_PWA_15790)
            self.asyncUpdateCartInfo({ cartInfo: res.info })
            daEventCenter.triggerNotice({ daId: '1-8-7-34' })
            daEventCenterV2.triggerNotice({ daId: 'expose_save_tips:simple', extraData: {
              $options: {
                sa_v2: true
              }}})
          } else {
            if (res.code == -4 || res.code == -2) {
              self.$toast(this.language.SHEIN_KEY_PWA_17639)
            } else if (Number(res.code) === 400427 && res.info){
              this.$toast(template(res.info.limit, this.language.SHEIN_KEY_PWA_18556))
            } else {
              self.$toast(res.msg)
            }
          }
        })
    },
    // 曝光事件
    exposeEvents () {
      this.daEventExpose = daEventCenter.getExposeInstance()
      this.$nextTick(() => {
        this.daEventExpose.subscribe({
          keycode: 'page-cart-save-all-expose-events`1-8-7-33'
        })
      })
    }
  }
}
</script>
<style lang="less">
.mcart-item {
  line-height: 1;
  // height: 1rem;

  padding: 20/75rem;
  background: #fff;
  // margin: 0 .21rem;
  color: #222;
  .flexbox();
  .space-between();
  .align-center();
  .font-dpr(28px);
  font-weight: bold;
  position: relative;
  &.platform-save-all{
    border-radius: 16/75rem 16/75rem 0 0;
  }
  i {
    .margin-r(.2rem);
    // color: #222;
    .font-dpr(48px);
  }
  .save-all{
    flex-shrink: 0;
    margin-left: 32/75rem;
    color: @color_brand;
    font-weight: bold;
    .font-dpr(24px);
    transition: all ease 0.1s;
    &.shaking{
      .margin-r(0.1rem)
    }
  }
}
</style>
