<template>
  <div
    v-expose="{
      id: '1-8-3-49',
      data: {
        coupon_type: targetCouponInfo.couponType,
        is_add: isSatisfied ? 0 : 1
      }
    }"
    class="coupon-filter"
  >
    <div class="left-coupon-tip">
      <div
        ref="couponTip"
        :class="isOverflow ? 'coupon-tip-overflow' : 'overflow-line2'"
      >
        <div
          ref="couponText"
          class="text"
          :class="{ 'overflow-line2': isOverflow }"
          v-html="couponTip"
        ></div>
        <s-popover
          ref="popover"
          v-model="showPop"
          trigger-type="click"
          class="pop-icon"
          placemen="bottom"
          :prop-style="couponFilterPopoverStyle"
          :content="popoverTip"
        >
          <Icon
            slot="reference"
            name="sui_icon_doubt_12px_2"
            size="12px"
            color="rgba(0, 0, 0, 0.6)"
          />
        </s-popover>
      </div>
      <div
        v-if="showCountDown"
        class="count-down"
      >
        <span class="end-in">{{ language.SHEIN_KEY_PWA_26614 }}</span>
        <count-down-time
          class="time"
          notReload
          notHide
          :font-size="20/75 + 'rem'"
          :time-stamp="Number(targetCouponInfo.end_date)"
        />
      </div>
    </div>
    <div class="right-button">
      <div
        v-if="!isSatisfied"
        class="add-btn"
        @click="handleAddOn"
      >
        <SAdapterText
          min-size="10"
          :text="language.SHEIN_KEY_PWA_18957"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { Icon } from '@shein-aidc/icon-vue2'
import { AdapterText as SAdapterText } from '@shein/sui-mobile'
import { template } from '@shein/common-function'
import { getCouponDiff, getDiscountByLevel } from 'public/src/pages/cartNew/utils/cartFilterLabels.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { throttle } from '@shein/common-function'
import { is0ThresholdCoupon } from 'public/src/pages/cartNew/utils/coupon.js'

export default {
  name: 'CartFilterCoupon',
  components: {
    Icon,
    SAdapterText,
  },
  data () {
    return {
      showPop: false,
      isOverflow: false,
      intervalId: false,
    }
  },
  computed: {
    ...mapState('cart', ['targetCouponInfo', 'language', 'locals']),
    ...mapGetters('cart', ['selectPrice', 'carts']),
    couponFilterPopoverStyle() {
      return {
        maxWidth: 240 / 37.5 + 'rem',
      }
    },
    // 判断当前券是否已凑满最高门槛
    isSatisfied() {
      const { satisfied_range, other_coupon_rule } = this.targetCouponInfo
      return Number(satisfied_range) >= other_coupon_rule?.length
    },
    /**
     * 优惠券使用情况提示
     * 零门槛           SHEIN_KEY_PWA_31178
     * 未达到最低门槛    SHEIN_KEY_PWA_30074
     * 达到最高门槛      SHEIN_KEY_PWA_30076
     * 达到最低未达到最高 SHEIN_KEY_PWA_30075
     */
    couponTip() {
      const { SHEIN_KEY_PWA_30074, SHEIN_KEY_PWA_30075, SHEIN_KEY_PWA_30076, SHEIN_KEY_PWA_31178 } = this.language
      const { satisfied_range, other_coupon_rule, realDiscountPrice } = this.targetCouponInfo

      if (is0ThresholdCoupon(this.targetCouponInfo)) {
        return this.template(
          this.getDiscountByLevel(this.targetCouponInfo, 0, this.locals),
          SHEIN_KEY_PWA_31178,
        )
      } else if (satisfied_range == '0') {
        return this.template(
          this.getCouponDiff(this.targetCouponInfo, true),
          this.getDiscountByLevel(this.targetCouponInfo, 0, this.locals),
          SHEIN_KEY_PWA_30074,
        )
      } else if (Number(satisfied_range) >= other_coupon_rule?.length) {
        return this.template(
          realDiscountPrice?.amountWithSymbol,
          SHEIN_KEY_PWA_30076,
        )
      } else {
        return this.template(
          realDiscountPrice?.amountWithSymbol,
          this.getCouponDiff(this.targetCouponInfo, true),
          this.getDiscountByLevel(this.targetCouponInfo, satisfied_range, this.locals),
          SHEIN_KEY_PWA_30075,
        )
      }
    },
    /**
     * 气泡内容
     * 未达到门槛 - 券前价   SHEIN_KEY_PWA_30073
     * 未达到门槛 - 券后价   SHEIN_KEY_PWA_30077
     * 达到门槛 - 自动用券   SHEIN_KEY_PWA_30078
     * 达到门槛 - 非自动用券 SHEIN_KEY_PWA_30079
     */
    popoverTip() {
      const autoUseCoupon = Number(this.targetCouponInfo?.is_best) == 1
      const isBeforePrice = this.selectPrice == 'beforecouponprice' 
      const {
        SHEIN_KEY_PWA_30078,
        SHEIN_KEY_PWA_30079,
        SHEIN_KEY_PWA_30073,
        SHEIN_KEY_PWA_30077,
      } = this.language

      if (this.isSatisfied) {
        return autoUseCoupon ? SHEIN_KEY_PWA_30078 : SHEIN_KEY_PWA_30079
      } else {
        return isBeforePrice ? SHEIN_KEY_PWA_30073 : SHEIN_KEY_PWA_30077
      }
    },
    showCountDown() {
      const endTimeStamp = Number(this.targetCouponInfo.end_date)
      const currentTimeStamp = Math.floor(Date.now() / 1000)
      const differenceInHours = (endTimeStamp - currentTimeStamp) / 3600
      return differenceInHours < 72
    },
    filterData () {
      const goodsIds = []
      const cateIds = []
      if (this.carts && this.carts.length) {
        this.carts.forEach(item => {
          if (item.product) {
            goodsIds.push(item.product.goods_id)
            cateIds.push(item.product.cat_id)
          }
        })
      }
      return {
        goodsIds,
        cateIds,
      }
    },
  },
  watch: {
    showPop(val) {
      val && daEventCenter.triggerNotice({ 
        daId: '1-8-3-51' 
      })
    },
    couponTip: {
      handler(newVal) {
        if (!newVal) return
        this.$nextTick(() => {
          const popover = this.$refs['popover']
          const couponTip = this.$refs['couponTip']
          const popoverRect = popover.$el.getBoundingClientRect()
          const couponTipRect = couponTip.getBoundingClientRect()
          this.isOverflow = popoverRect.bottom > couponTipRect.bottom
        })
      },
      immediate: true
    },
    // 达到最高门槛，触发一次气泡自动弹出
    isSatisfied(newVal, oldVal) {
      if (!oldVal && newVal) {
        if (window?.sessionStorage.getItem('CartCouponSatisfiedPop')) return
        this.autoShowPopover()
        window?.sessionStorage.setItem('CartCouponSatisfiedPop', true)
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (window?.sessionStorage.getItem('CartCouponFirstPop')) return
      this.autoShowPopover()
      window?.sessionStorage.setItem('CartCouponFirstPop', true)
    })
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapMutations('cart', ['updateState']),
    template,
    getCouponDiff,
    getDiscountByLevel,
    handleAddOn() {
      const { coupon, couponType, satisfied_range } = this.targetCouponInfo
      // 打开凑单弹窗
      this.updateState({
        key: 'addOnInfo', 
        value: {
          show: true,
          coupon: coupon,
          state: 'cart_filter_coupon_add',
          config: {
            isClickToDetail: false,
          }
        }
      })
      daEventCenter.triggerNotice({
        daId: '1-8-3-50',
        extraData: {
          coupon_type: couponType,
          level: Number(satisfied_range)
        }
      })
    },
    /**
     * 气泡自动弹出逻辑
     * 一次会话最多弹两次，一次是选中标签时的， 一次是达到最高门槛时
     */
    autoShowPopover () {
      let countdown = 3
      this.showPop = true
      this.intervalId = setInterval(() => {
        countdown -= 1
        if (countdown <= 0) {
          clearInterval(this.intervalId)
          this.showPop = false
          this.intervalId = null
        }
      }, 1000)
    },
    // 页面发生滚动时，不显示气泡
    handleScroll: throttle({ 
      func: function() {
        if (this.showPop && !this.intervalId) {
          this.showPop = false
        }
      },
      wait: 300,
      options: {
        trailing: false
      }
    }),
  },
}
</script>

<style lang="less" scoped>
.coupon-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin: 16/75rem 12/75rem 0;
  min-height: 100/75rem;
  max-height: 128/75rem;
  border-radius: 8px;
  border: 0.5px solid #FFF;
  background-image:
    url('/pwa_dist/images/cart/coupon-filter-bg-cf614cc17e.png'),
    linear-gradient(90deg, #FFF0F1 30.7%, #FFE1E3 57.37%, #FFE4D4 84.96%, #FFDED7 100%);
  background-size: 88/37.5rem 42/37.5rem, cover;
  background-position: 188/37.5rem bottom, top;
  background-repeat: no-repeat;
  &::before, &::after {
    content: '';
    display: block;
    width: 16/75rem;
    height: 16/75rem;
    position: absolute;
    right: 140/75rem;
    border-radius: 50%;
    background: #f6f6f6;
    border-left: 0.5px solid #FFF;
    border-bottom: 0.5px solid #FFF;
    border-top: 0.5px solid #f6f6f6;
    border-right: 0.5px solid #f6f6f6;
  }
  &::before {
    top: -8/75rem;
    transform: rotate(-45deg);
  }
  &::after {
    bottom: -8/75rem;
    transform: rotate(135deg);
  }
}
.left-coupon-tip {
  box-sizing: border-box;
  width: 288/37.5rem;
  padding: 16/75rem 16/75rem 16/75rem 24/75rem;
  .text {
    display: inline;
    font-family: "SF Pro";
    font-size: 24/75rem;
    font-weight: 510;
    line-height: 14/37.5rem;
  }
  .pop-icon {
    line-height: 14/37.5rem;
    margin-left: 4/75rem;
  }
  .overflow-line2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .coupon-tip-overflow{
    display: inline-flex;
    align-items: flex-end;
    .text {
      flex: 1;
    }
    .pop-icon {
      width: 12px;
    }
  }
  .count-down {
    margin-top: 6/75rem;
    height: 36/75rem;
    line-height: 36/75rem;
    .end-in {
      font-size: 20/75rem;
      font-style: normal;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.80);
    }
    .time {
      display: inline-block;
      margin-left: 8/75rem;
      color: #FA6338;
    }
  }
}
.right-button {
  flex: 1;
  min-height: 68/75rem;
  max-height: 96/75rem;
  padding: 16/75rem;
  border-left: dashed 0.5px #F3B9A1;
  .add-btn {
    display: block;
    width: fit-content;
    min-width: 92/75rem;
    max-width: 116/75rem;
    height: 40/75rem;
    text-align: center;
    border-radius: 20/75rem;
    background: #FA6338;
    font-size: 24/75rem;
    font-weight: 700;
    line-height: 40/75rem;
    padding: 0 8/75rem;
    margin: 0 auto;
    color: #fff;
  }
}
.text {
  /deep/strong {
    font-weight: normal;
  }
  /deep/font {
    font-weight: bold;
  }
}
</style>
