<template>
  <div
    class="checked-tip"
  >
    <div class="checked-tip__content">
      <div
        class="checked-tip__text"
        v-html="content"
      >
      </div>
      <div
        v-if="btnText"
        class="checked-tip__btn"
        @click="handleClick"
      >
        <span>{{ btnText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 点击勾选按钮的提示
 * 集成了点击事件和滚动事件监听
 */
export default {
  name: 'CheckedTip',
  props: {
    /**
     * 内容
     */
    content: {
      type: String,
      default: ''
    },
    /**
     * 按钮文字
     */
    btnText: {
      type: String,
      default: ''
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.listenScroll()
      this.listenClick()
    })
  },
  methods: {
    listenScroll() {
      document.addEventListener('scroll', this.handleClose)
    },
    removeScroll() {
      document.removeEventListener('scroll', this.handleClose)
    },
    listenClick() {
      document.addEventListener('click', this.handleClickOutside)
    },
    removeClick() {
      document.removeEventListener('click', this.handleClickOutside)
    },
    handleClick() {
      this.$emit('click')
      this.handleClose()
    },
    handleClose() {
      this.$emit('close')
      this.removeScroll()
      this.removeClick()
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.handleClose()
      }
    },
  }
}
</script>

<style lang="less" scoped>
.checked-tip {
  width: max-content;
  &__content {
    max-width: 240/37.5rem;
    border-radius: 2/37.5rem;
  }
  &__text {
    font-size: 12px;
    line-height: 14/37.5rem;
    color: #FFF;
    text-align: center;
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  &__btn {
    display: flex;
    width: fit-content;
    margin: 8/37.5rem auto 0;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    height: 20/37.5rem;
    padding: 2/37.5rem 8/37.5rem;
    border-radius: 30/37.5rem;
    background: #FA6338;
    span {
      flex: 1;
      min-width: 0;
      font-size: 12px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: #fff;
    }
  }
}
</style>

