<template>
  <div>
    <ClientOnly>
      <div
        v-if="returnFlagTip"
        class="cart-return-flag-tip"
        @click="onClick"
      >
        <sui_icon_caution_18px
          class="cart-return-flag-tip__left-icon"
          size="12px"
        />
        <div class="cart-return-flag-tip__content">
          <TextEllipsis
            ref="refTextEllipsis"
            :expanded.sync="expanded"
            :has-action.sync="hasAction"
            :content="returnFlagTip"
            :max-rows="5"
          />
        </div>
        <sui_icon_more_down_16px
          class="cart-return-flag-tip__right-icon"
          :class="[
            expanded && 'cart-return-flag-tip__icon-flip',
            hasAction && 'cart-return-flag-tip__has-action'
          ]"
          size="12px"
        />
      </div>
    </ClientOnly>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import ClientOnly from 'vue-client-only'
import { sui_icon_caution_18px, sui_icon_more_down_16px } from '@shein-aidc/icon-vue2'
import TextEllipsis from '../../TextEllipsis.vue'

const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: null
  }
})

const returnFlagTip = computed(() => {
  if (typeof window === 'undefined' || !props.language) return ''
  const { feRefundAble, return_flag, isCustomGood } = props.item
  if (gbCommonInfo.langPath === '/de') {
    if (isCustomGood) return props.language.SHEIN_KEY_PWA_33401
    if (return_flag) {
      if (feRefundAble === 1) return props.language.SHEIN_KEY_PWA_33403
      if (feRefundAble === 0) return props.language.SHEIN_KEY_PWA_33402
    }
    return ''
  } else {
    if (isCustomGood) return props.language.SHEIN_KEY_PWA_33401
    if (return_flag === 1) return props.language.SHEIN_KEY_PWA_33399
    if (return_flag === 2) return props.language.SHEIN_KEY_PWA_33400
    if (return_flag === 3) return props.language.SHEIN_KEY_PWA_33400
    return ''
  }
})

const refTextEllipsis = ref()
const expanded = ref(false)
const hasAction = ref(false)

const onClick = () => {
  if (!hasAction.value) return
  refTextEllipsis.value?.toggle()
}
</script>

<style lang="less" scoped>
.cart-return-flag-tip {
  display: flex;
  margin-top: 8/37.5rem;
  font-size: 10px;
  line-height: 12px;
  color: #959595;

  &__left-icon {
    flex-shrink: 0;
  }

  &__content {
    flex: 1;
    margin: 0 4/37.5rem;
  }

  &__right-icon {
    flex-shrink: 0;
    align-self: flex-end;
    opacity: 0;
  }

  &__icon-flip {
    transform: rotateZ(180deg);
  }

  &__has-action {
    opacity: 1;
  }
}
</style>
