<template>
  <component
    v-bind="bindData"
    :is="componentName"
  />
</template>

<script setup>
import { computed, watch } from 'vue'
import { useStore } from 'public/src/pages/cartNew/utils/index.js'
import { freeShippingDaEvent } from 'public/src/pages/cartNew/components/cartRightsFuns/rightsFunsTools.js'
import NewUser from './NewUser.vue'
import OldUserA from './OldUserA.vue'
import OldUserB from './OldUserB.vue'

const rootStore = useStore()
const componentName = computed(() => {
  if (rootStore?.state?.cart?.isNewUserOfTsp) return NewUser
  if (rootStore?.getters?.['cart/cartRightsForOldUser'] === 'planA') return OldUserA
  if (rootStore?.getters?.['cart/cartRightsForOldUser'] === 'planB') return OldUserB
  return null
})
const language = computed(() => {
  return rootStore?.state?.cart?.language || {}
})
const isPlatForm = computed(() => {
  return rootStore?.state?.cart?.isPlatForm || false
})
const singleMallCode = computed(() => {
  return rootStore?.getters?.['cart/singleMallCode'] || {}
})
const mallShippingAnalysisParam = computed(() => {
  return rootStore?.getters?.['cart/mallShippingAnalysisParam'] || {}
})
const showFreeShipping = computed(() => {
  return rootStore?.getters?.['cart/showFreeShipping4RightsFuns'] || false
})
const isFreeReturn = computed(() => {
  return rootStore?.state?.cart?.isFreeReturn || false
})
const newFreeShippingInfo = computed(() => {
  return rootStore?.getters?.['cart/newFreeShippingInfo'] || {}
})
const topShippingInfo = computed(() => {
  return rootStore?.getters?.['cart/topShippingInfo'] || {}
})
const freeShippingInfo = computed(() => {
  if (newFreeShippingInfo.value) {
    return {
      dataFrom: 'newFreeShippingInfo',
      info: newFreeShippingInfo.value
    }
  } else if (topShippingInfo.value) {
    return {
      dataFrom: 'topShippingInfo',
      info: topShippingInfo.value
    }
  } else {
    return {
      dataFrom: null,
      info: {}
    }
  }
})
const bindData = computed(() => {
  return {
    language: language.value,
    isFreeReturn: isFreeReturn.value,
    showFreeShipping: showFreeShipping.value,
    freeShippingInfo: freeShippingInfo.value,
  }
})
watch(() => showFreeShipping.value, (val) => {
  if (typeof window !== 'undefined' && val) {
    const params = {
      freeShippingInfo: freeShippingInfo.value,
      isPlatForm: isPlatForm.value,
      singleMallCode: singleMallCode.value,
      mallShippingAnalysisParam: mallShippingAnalysisParam.value
    }
    freeShippingDaEvent(params)
  } 
}, { immediate: true })
</script>
