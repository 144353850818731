<template>
  <s-checkbox-tag
    class="filter-check-tag"
    :class="{
      'style-a': abtStyle == 'A',
      'style-b': !inModal && abtStyle == 'B',
      'style-b-modal': inModal && abtStyle == 'B',
      'j_coupon-label': isCoupon(label),
      'scan-lights': isCoupon(label) && showScanLights
    }"
    style-type="white"
    :hide-icon="true"
    :label="isCoupon(label) ? label.id : label.type"
    :cancel-mark="cancelMark"
  >
    <div class="filter-label">
      <template v-if="showPrefixIcon">
        <Icon
          v-if="prefixIcon"
          size="12px"
          class="prefix-icon custom-prefix-icon"
          :name="prefixIcon"
        />
        <Icon
          v-else
          size="12px"
          class="prefix-icon"
          :data-type="label.type"
          :is-responsive-name="true"
          :name="getIconName(label)"
        />
      </template>
      <i
        v-if="showDivider"
        class="divider"
      ></i>
      <TransitionGroup 
        name="slide" 
        tag="div"
        class="transition-tag"
      >
        <div 
          v-for="item in slideItems" 
          :key="item"
          class="label-tip"
        >
          {{ item }}
        </div>
      </TransitionGroup>
      <div></div>
      <Icon
        v-if="showSuffixIcon && showClose && checked"
        class="suffix-icon"
        name="sui_icon_close_16px" 
        size="14px"
      />
    </div>
  </s-checkbox-tag> 
</template>
<script>
import { Icon } from '@shein-aidc/icon-vue2'

export default {
  name: 'FilterCheckTag',
  components: { Icon },
  props: {
    label: {
      type: Object,
      default: () => ({}),
    },
    abtStyle: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    prefixIcon: {
      type: String,
      default: '',
    },
    showPrefixIcon: {
      type: Boolean,
      default: false,
    },
    showSuffixIcon: {
      type: Boolean,
      default: false,
    },
    showScanLights: {
      type: Boolean,
      default: false,
    },
    showDivider: {
      type: Boolean,
      default: false,
    },
    cancelMark: {
      type: Boolean,
      default: true,
    },
    inModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      slideItems: [this.label?.tip],
    }
  },
  computed: {
    showClose() {
      return ['A', 'B'].includes(this.abtStyle)
    }
  },
  watch: {
    'label.tip'(val) {
      this.slideItems.shift()
      this.$nextTick(() => {
        this.slideItems.push(val)
      })
    }
  },
  methods: {
    isCoupon(label) {
      return label.type == 'coupon'
    },
    getIconName(label) {
      const iconName = label?.iconName
      if (this.abtStyle == 'A') {
        return this.checked ? iconName?.aChecked : iconName?.a
      } else if (this.abtStyle == 'B') {
        return this.checked ? iconName?.bChecked : iconName?.b
      } else {
        return this.checked ? iconName?.defaultChecked : iconName?.default
      }
    },
  },
}
</script>

<style lang="less" scoped>
.filter-check-tag {
  width: auto;
  /deep/.S-checkbox-tag__wrap {
    line-height: 0;
    overflow: hidden;
  }
}
.filter-label {
  display: inline-flex;
  align-items: center;
  height: 100%;
  font-size: 12/37.5rem;
  max-width: 156/37.5rem;
  .transition-tag {
    flex: 1;
    overflow: hidden;
  }
  .label-tip {
    height: 16px;
    line-height: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .divider {
    width: 1/37.5rem;
    height: 10/37.5rem;
    background: #999;
    margin-right: 4/37.5rem;
  }
  /deep/.prefix-icon {
    margin-right: 8/75rem;
    &[data-type="flash_sale"] {
      color: @sui_color_flash!important;/* stylelint-disable-line declaration-no-important */
      /*rw:begin*/
      color: @sui_color_promo!important;/* stylelint-disable-line declaration-no-important */
    }
    &[data-type="almost_out_of_stock"] {/* stylelint-disable-line */
      color: @sui_color_highlight!important;/* stylelint-disable-line declaration-no-important */
      /*rw:begin*/
      color: @sui_color_discount!important;/* stylelint-disable-line declaration-no-important */
    }
    &[data-type="markdowns"], &[data-type="discount_limit"], &[data-type="limit_off"], &[data-type="under_price"], &[data-type="5star"], &[data-type="30day_sale"], &[data-type="30day_additem"] {
      color: #C44A01!important;/* stylelint-disable-line declaration-no-important */
    }
    &[data-type="quickship"] {
      color: #198055!important;/* stylelint-disable-line declaration-no-important */;
    }
    &.custom-prefix-icon {
      color: #666!important;/* stylelint-disable-line declaration-no-important */
    }
  }
  /deep/.suffix-icon {
    margin-left: 8/75rem;
  }
}

.style-a, .style-b, .style-b-modal {
  /deep/.S-checkbox__input-custom {
    .icons-share_cancel {
      display: none;
    }
    .S-checkbox-tag__wrap {
      border-radius: 24/37.5rem;
      border: 1px solid #E5E5E5;
      &::after {
        border: none;
      }
    }
  }
  &.scan-lights {
    border-radius: 24/37.5rem;
  }
}
/deep/.S-checkbox__input-custom_checked {
  .prefix-icon.custom-prefix-icon {
    color: #000!important;/* stylelint-disable-line declaration-no-important */
  }
}
.style-a {
  /deep/.S-checkbox__input-custom_checked {
    .S-checkbox-tag__wrap {
      border: 1px solid #FA6338;
      background: #FFF6F3;
      color: #FA6338;
    }
    .divider {
      background: #FA6338;
      opacity: 0.6;
    }
    .custom-prefix-icon {
      color: #FA6338!important;/* stylelint-disable-line declaration-no-important */
    }
  }
}
.style-b {
  /deep/.S-checkbox__input-custom_checked {
    .S-checkbox-tag__wrap {
      border: 1px solid #222;
      background: #222;
      color: #FFF;
    }
    .prefix-icon, .suffix-icon {
      color: #FFF!important;/* stylelint-disable-line declaration-no-important */
    }
  }
}
.style-b-modal {
  /deep/.S-checkbox__input-custom_checked {
    .S-checkbox-tag__wrap {
      border: 1px solid #000;
      background: #fff;
      color: #000;
    }
    .prefix-icon, .suffix-icon {
      color: #000!important;/* stylelint-disable-line declaration-no-important */
    }
  }
}

.scan-lights {
  position: relative;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to right, rgba(255, 230, 167, 0), rgba(255, 230, 167, 1), rgba(255, 230, 167, 0));
    animation: ScanLights 1.5s linear 2 forwards;
  }
}
@keyframes ScanLights {
  0% {
    transform: translate(-100%);
  }
  100% {
    transform: translate(100%);
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: all .2s linear;
}
.slide-enter {
  transform: translate3d(0, 100%, 1px);

}
.slide-leave-to {
  opacity: 0;
  transform: translate3d(0, -100%, 1px);
}
</style>
