<script>
import { mapState, mapMutations } from 'vuex'
import { Icon } from '@shein-aidc/icon-vue2'
import { AdapterText as SAdapterText } from '@shein/sui-mobile'
import { scrollToRecommend, analysis } from './utils'
import imgStackGallery from '@/public/src/pages/cartNew/components/unPaidOrderTip/imgStackGallery.vue'

export default {
  name: 'RecommendGuideA',
  components: {
    Icon,
    SAdapterText,
    imgStackGallery,
  },
  data() {
    return {
      isIconOnly: false,
    }
  },
  computed: {
    ...mapState('cart', ['language', 'recommendGoods']),
    imgList() {
      return this.recommendGoods?.slice(0, 3)?.map((goods) => {
        return {
          goods_img: goods.goods_img
        }
      })
    }
  },
  mounted() {
    this.$nextTick(() => {
      analysis('1-8-3-74', 'A')
      const _this = this
      _this.isIconOnly = _this.$refs.viewTextRef?.scrollWidth > _this.$refs.viewTextRef?.clientWidth
      _this.timer = setTimeout(() => {
        _this.close()
      }, 5000)
    })
  },
  beforeDestroy() {
    if(this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  },
  methods: {
    ...mapMutations('cart', ['updateState']),
    scrollToRecommend,
    view() {
      this.scrollToRecommend('A')
      this.close()
    },
    close() {
      this.updateState({
        key: 'showRecommendGuideA',
        value: false
      })
    },
    handleClickClose() {
      this.close()
      analysis('1-8-3-76', 'A')
    }
  },
}
</script>

<template>
  <div class="recommend-guide-wrap__a">
    <imgStackGallery
      v-if="imgList.length"
      :img-list="imgList"
      count="99+"
    />
    <div
      class="text-wrap" 
      @click="view"
    >
      <SAdapterText
        class="title"
        min-size="12"
        :text="language.SHEIN_KEY_PWA_32684"
      />
      <SAdapterText
        class="tip"
        min-size="10"
        :text="language.SHEIN_KEY_PWA_32685"
      />
    </div>
    <div
      :class="['view-btn', { 'icon-only': isIconOnly }]"
      @click="view"
    >
      <span
        ref="viewTextRef"
        class="view-text"
      >{{ language.SHEIN_KEY_PWA_32145 }}</span>
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.6"
          d="M9 3L6 6L3 3"
          stroke="white"
          stroke-linejoin="round"
        />
        <path
          opacity="0.8"
          d="M9 6L6 9L3 6"
          stroke="white"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <Icon
      name="sui_icon_close_16px_2"
      size="16px"
      color="rgba(193, 193, 193, 1)"
      @click="handleClickClose"
    />
  </div>
</template>

<style lang="less" scoped>
.recommend-guide-wrap__a {
  z-index: 99;
  width: calc(100% - 24px);
  height: 96/75rem;
  border-radius: 8/75rem;
  margin: 0 12px;
  padding: 16/75rem 18/75rem;
  display: inline-flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  .text-wrap {
    flex: 1;
    overflow: hidden;
    margin-left: 16/75rem;
    .title {
      font-size: 14px;
      color: rgba(255, 255, 255, 1);
      line-height: 17px;
    }
    .tip {
      color: rgba(255, 255, 255, .7);
      font-size: 12px;
      line-height: 14px;
    }
  }
  .view-btn {
    width: 104/75rem;
    height: 48/75rem;
    padding: 6/75rem 8/75rem;
    border-radius: 26/75rem;
    margin: 0 16/75rem;
    background: rgba(250, 99, 56, 1);
    color: #ffffff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 24/75rem;
    .view-text {
      flex: 1;
      width: fit-content;
      overflow: hidden;
      text-align: center;
    }
    &.icon-only {
      width: 48/75rem;
      height: 48/75rem;
      border-radius: 16/75rem;
      .view-text {
        display: none;
      }
    }
  }
}
</style>
