<template>
  <div class="cart-rightsFuns-tip_oldB">
    <span class="left-tip">
      <Icon
        class="item-icon"
        name="sui_icon_selected_16px"
        size="16px"
        color="#0E8C33"
      />
      <span
        class="tip-text"
        v-html="tipsText"
      ></span>
    </span>
    <template v-if="hasCountDown">
      <ClientOnly>
        <count-down
          font-size="10px"
          not-hide
          not-reload
          :is-left-time="isLeftTime"
          :time-stamp="countDownTime"
        />
      </ClientOnly> 
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import CountDown from 'public/src/pages/cartNew/components/cartActivity/countDown.vue'

const props = defineProps({
  language: {
    type: Object,
    default: () => ({}),
  },
  isFreeReturn: {
    type: Boolean,
    default: false,
  },
  showFreeShipping: {
    type: Boolean,
    default: false,
  },
  freeShippingInfo: {
    type: Object,
    default: () => ({}),
  }
})

const hasCountDown = computed(() => {
  return props.freeShippingInfo.info?.showCountDown || props.freeShippingInfo.info?.shippingInfoAvailable?.show_count_down || false
})
const countDownTime = computed(() => {
  return props.freeShippingInfo.info?.countDownTime || props.freeShippingInfo.info?.shippingInfoAvailable?.count_down_time || null
})
const isLeftTime = computed(() => {
  return props.freeShippingInfo.info?.type === 'coupon' ? false : true
})
const tipsText = computed(() => {
  return props.isFreeReturn
    ? props.language.SHEIN_KEY_PWA_33455
    : props.freeShippingInfo.info?.freeShippinpTips || props.freeShippingInfo.info?.shippingTip || ''
})
</script>

<style lang="less" scoped>
.cart-rightsFuns-tip_oldB {
  width: 100%;
  min-height: 64/75rem;
  padding: 16/75rem 24/75rem;
  background: #FFF3D3;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  .left-tip {
    flex-shrink: 1;
    display: inline;
    overflow: hidden;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    line-height: 28/75rem;
    .item-icon {
      display: inline;
      line-height: 28/75rem!important;
      margin-right: 4/75rem;
    }
    .tip-text {
      font-size: 24/75rem;
      font-weight: 400;
    }
  }
  /deep/.cont-down {
    margin-left: 16/75rem;
    flex-shrink: 0;
    span {
      display: inline-block;
      text-align: center;
      background: rgba(0, 0, 0, 1);
      color: rgba(255, 255, 255, 1);
      border-radius: 1px;
      width: 14px;
      height: 14px;
      line-height: 14px;
      font-size: 10px;
    }
  }
}
</style>
